<!--
Public: Mixin containing common popup properties.

Data Properties
  dataMessage - The message displayed in the popup (default: '').
  dataVisible - Whether or not the popup is visible (default: false);

Properties
  message   - Message to display (default: '').
  popupType - Type of popup to display. This will be either 'alert-success' or 'alert-danger', depending on this.type.
  visible   - Whether or not the popup is visible (default: false).
-->

<template>
  <transition name="fade">
    <div v-show = "dataVisible"
         :id    = "popupId"
         :class = "popupCSSClass"
         style  = "display:none;">
      <button-close @click.native="dataVisible = false"></button-close>
      <div v-html="dataMessage"></div>
    </div>
  </transition>
</template>

<script>
  import ButtonClose           from '../components/button_close.vue'
  import isArray               from 'lodash/isArray'
  import {blank, defaultValue} from '../lib/utils.js'

  const DEFAULT_TIMEOUT = 3000;

  export default {
    components: {
      ButtonClose
    },

    data: function() {
      return {
        // Public: The message displayed in the popup (default: '').
        dataMessage: '',
        // Public: Whether or not the popup is visible (default: false);
        dataVisible: false
      }
    },

    props: {
      // Public: Message to display (default: '').
      message: { default: '' },
      // Public: Whether or not the popup is visible (default: false).
      visible: { default: false }
    },

    computed: {
      // Public: Get the CSS class(es).
      //
      // Returns a string.
      popupCSSClass: function() {
        return 'alert alert-centered overflow-hidden overflow-y-scroll max-height-600 ' + this.popupType;
      },

      // Public: Id of the popup.
      //
      // Returns a string.
      popupId: function() {
        return 'popup-messages-' + this.type;
      },

      // Public: Type of popup to display. This will be either 'alert-success' or 'alert-danger', depending on this.type.
      //
      // Returns a string.
      popupType: function() {
        return this.type == 'danger' ? 'alert-danger' : 'alert-success';
      }
    },

    methods: {
      // Public: Hide the popup.
      hide: function() {
        this.dataVisible = false;
      },

      // Public: Display the popup.
      //
      // message - Message to display.
      // timeout - How long, in milliseconds, to display the popup (default: i4cp.Components.Vue.Mixins.PopupMixin.DEFAULT_TIMEOUT).
      //           If a value <= 0 is given the popup will only disappear if the "x" button is clicked.
      show: function(message, timeout) {
        if(blank(message)) {
          return;
        }

        timeout          = defaultValue(timeout, DEFAULT_TIMEOUT);
        this.dataMessage = isArray(message) ? message.join('<br />') : message;
        this.dataVisible = true;
        var _this        = this;

        if(timeout > 0) {
          setTimeout(function() {
            _this.dataVisible = false;
          }, timeout);
        }
      }
    },

    created: function() {
      this.dataMessage = this.message;
      this.dataVisible = this.visible;
    }
  }
</script>
