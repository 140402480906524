import { render, staticRenderFns } from "./mobile_app_banner.vue?vue&type=template&id=c1685b48"
import script from "./mobile_app_banner.vue?vue&type=script&lang=js"
export * from "./mobile_app_banner.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports