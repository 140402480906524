import map               from 'lodash/map'
import {nullOrUndefined} from './utils.js';

// Public: Capitalize the first letter of a string.
//
// string - Capitalize this string.
//
// Returns a capitalized string.
export function capitalize(string) {
  if(nullOrUndefined(string)) {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1, string.length);
}

// Public: Capitalize all words in a string; remove hyphens and underscores.
//
// string - string to capitalize
//
// Returns: string
export function capitalizeAllWords(string) {
  if(nullOrUndefined(string)) {
    return '';
  }

  return map(string.split(/[ \-_]/), capitalize).join(' ');
};

// Public: Convert common words to appropriate case or symbol
//
// string - string to process
//
// Returns a string
export function convertStopWords(string) {
  var matches = {
        'And': '&',
        'Of' : 'of',
        'Hr' : 'HR'
      };

  return string.split(/\s/)
               .map(function(word) {
                 return matches[word] || word;
               })
               .join(' ');
}


// Public: Converts an Object into a querystring.
//
// obj - Object
//
// Returns a string.
export function objectToQueryString(obj) {
  return new URLSearchParams(obj).toString()
}

// Public: Convert the given string into a permalink slug.
//
// string - String to change.
//
// Returns a String.
export function permalize(string) {
  return string.replace(/[ _]/g, '-')
               .replace(/[^\w-]+/g, '')
               .replace(/-+/g, '-')
               .toLowerCase()
}

// Public: Converts a URL querysting into an object
//
// string - querystring (e.g. document.location.search)
//
// Returns an Object
export function queryStringToObject(string) {
  return Object.fromEntries(
    new URLSearchParams(string)
  )
}


// Public: Trim white space off the front and back of the given string.
//
// string - Trim this string.
//
// Returns a string with white space trimmed off the front and back.
export function trim(string) {
  if(nullOrUndefined(string)) {
    return string;
  }

  return string.replace(/^\s+|\s+$/g, '');
}


// Public: Trim the trailing slash from a string.
//
// string - Trim the trailing slash from this string.
//
// Examples
//
//   i4cp.s.trimSlash('/this/is/a/test/'); // => '/this/is/a/test'
//   i4cp.s.trimSlash('/this/is/a/test');  // => '/this/is/a/test'
//
// Returns a string.
export function trimSlash(string) {
  return string.replace(/\/$/, '')
};

// Public: return the number of words in the post body
//
// post - return the word count from this post
export function wordCount(text) {
  return text.split(/\W/).length
};
