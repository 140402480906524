<!--
Public: Checks if the post is viewable by the current member via API call using the post's
permalink and displays a lock icon if the member cannot view the post

Example:
  <post-lock-icon :permalink="permalink" >
  </post-lock-icon>
-->
<template>
  <i v-if="display" class="border-radius-5 color-gray h-10 pa-5 mr-10 icon fa fa-lock"></i>
</template>

<script>
  import {get} from '../lib/async.js'

  export default {
    props: {
      // Local URL of post
      permalink: { default: '' },
    },

    data() {
      return {
        // Shows lock icon if true.
        display: false,
      }
    },

    created() {
      // Makes call to determine if the current member can view the post to conditionally render a lock icon.
      get('/api/posts/viewable', {permalink: this.permalink}).then((results)=>{
        this.display = !results
      }).catch((_err)=>{
        this.display = false
      })
    }
  }
</script>
