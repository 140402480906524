<!--
Public: Select for country and state/region

Example:
<country-dropdown
  country            = "<%= @user.country_code %>"
  country-field-id   = "user_country_code"
  country-field-name = "user[country_code]"
  horizontalPosition         = "true"
  region             = "<%= @user.region_code %>"
  region-field-id    = "user_region_code"
  region-field-name  = "user[region_code]"
>
</country-dropdown>
-->
<template>
  <div :class="classes">
    <div :class="formGroupClasses">
      <label v-if="internalShowLabel" for="country" :class="labelClasses">Country:*</label>
      <select :id       = "countryFieldId"
              :class    = "dropdownClasses"
              :required = "required"
              :name     = "countryFieldName"
              v-model   = "internalCountry"
              @change   = "displayRegions">
        <option value="">Please select a country</option>
        <option v-for="country in countries" :value="country.code">
          {{ country.name }}
        </option>
      </select>
    </div>
    <div v-if="showRegion" :class="formGroupClasses">
      <label v-if="internalShowLabel" for="state" :class="labelClasses">{{ regionType }}:</label>
      <select :id     = "regionFieldId"
              :class  = "dropdownClasses"
              :name   = "regionFieldName"
              v-model = "internalRegion">
        <option value="">Please select a region</option>
        <option v-for="region in regions" :value="region.code">
          {{ region.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
  import { get }     from '../lib/async.js'
  import { present, toBoolean } from '../lib/utils.js'

  const COUNTRY_URL              = "/api/country-list"
  const REGION_URL               = "/api/regions-by-country"
  const COUNTRIES_WITH_STATES    = [ 'AU', 'DE', 'MY', 'US' ]
  const COUNTRIES_WITH_PROVINCES = [ 'CA', 'IE' ]
  const COUNTRIES_WITH_COUNTIES  = [ 'GB', 'JM' ]
  const COUNTRIES_WITH_CANTONS   = [ 'CH' ]
  const COUNTRIES_WITH_REGIONS   = [
    ...COUNTRIES_WITH_STATES,
    ...COUNTRIES_WITH_PROVINCES,
    ...COUNTRIES_WITH_COUNTIES,
    ...COUNTRIES_WITH_CANTONS
  ]

  export default {
    props: {
      // ID of country form field.
      countryFieldId:     { default: '' },
      // Name of country form field.
      countryFieldName:   { default: '' },
      // Member edit page uses a horizontal orientation. Contact uses vertical.
      horizontalPosition: { default: false },
      // Places the label to the left of the dropdown, rather than above.
      horizontalAlignment: { default: false },
      // ID of region form field.
      regionFieldId:      { default: '' },
      // Name of region for field
      regionFieldName:    { default: '' },
      // User's country, if present.
      country:            { default: '' },
      // User's region/state/province, if present.
      region:             { default: '' },
      // true if required
      required:           { default: true },
      // allow for manual setting of the dropdown width by column
      dropdownWidth:      { default: '' },
      showLabel:          { default: true }
    },

    data() {
      return {
        // Public: API-populated array of countries.
        countries:       [],
        // Public: The currently-selected country.
        internalCountry: this.country,
        // Public: The currently-select region.
        internalRegion:  this.region,
        // Public: API-populated array of regions.
        regions:         [],
        // Internal: Whether to render the region dropdown.
        showRegion:      false,
        internalShowLabel: toBoolean(this.showLabel)
      }
    },

    computed: {
      // Internal: CSS wrapping classes
      classes() {
        return this.horizontalPosition ? 'col-md-6' : ''
      },

      // Internal: form-group classes will differ on form orientation.
      formGroupClasses() {
        if(this.horizontalAlignment)
          return 'form-group flex'
        else if(this.horizontalPosition)
          return 'form-group inline-block width-47p mr-2p'
        else
          return'form-group'
      },

      labelClasses() {
        return this.horizontalAlignment ? 'col-sm-4 text-align-right' : ''
      },

      dropdownClasses() {
        return present(this.dropdownWidth) ? `form-control ${this.dropdownWidth}` : 'form-control'
      },

      // Interal: Text to display for type of region
      regionType() {
        if (COUNTRIES_WITH_STATES.includes(this.internalCountry))    return 'State'
        if (COUNTRIES_WITH_PROVINCES.includes(this.internalCountry)) return 'Province'
        if (COUNTRIES_WITH_COUNTIES.includes(this.internalCountry))  return 'County'
        if (COUNTRIES_WITH_CANTONS.includes(this.internalCountry))   return 'Canton'

        return 'Region'
      }
    },

    methods: {
      // Public: Get the region for the selected country and show dropdown.
      displayRegions() {
        if (COUNTRIES_WITH_REGIONS.includes(this.internalCountry)) {
          get(`${REGION_URL}/${this.internalCountry}`)
            .then(res => { this.regions = res })
            .then(()  => { this.showRegion = true })
        } else {
          this.showRegion     = false
          this.internalRegion = ''
        }
      }
    },

    mounted() {
      get(COUNTRY_URL)
        .then(res => { this.countries = res })
        .then(this.displayRegions)
    }

  }
</script>
