import axios         from 'axios';
import { serialize } from 'object-to-formdata'

// Internal: Do not allow more than this many concurrent get requests.
const MAX_REQUESTS     = 3
// Internal: Keep track of the current number of get requests.
let   PENDING_REQUESTS = 0

document.addEventListener("DOMContentLoaded", function(event) {
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  axios.defaults.headers.common['X-CSRF-Token']     = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
})

// Public: Decorative wrapper around axios.put to keep the API the same as the
// existing i4cp async function.
//
// url    - Endpoint URL.
// params - Querystring parameters.
//
// Returns a Promise.
export function destroy(url, params) {
  return axios.delete(url, { params: params })
              .then(function(results) {
                return results.data
              });
}

// Public: Decorative wrapper around axios.get to keep the API the same as the
// existing i4cp async function.
//
// url    - Endpoint URL.
// params - Querystring parameters.
//
// Returns a Promise.
export function get(url, params) {
  return axios.get(url, { params: params })
              .then(function(results) {
                return results.data
              })
}

// Public: axios.get call but with rate limits implemented. This should prevent pages like the
// dashboard from API spamming the server.
//
// Returns a Promise.
export function getWithLimits(url, params) {
  return new Promise((resolve, reject) => {

    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS) {
        PENDING_REQUESTS++
        clearInterval(interval)

        let results = axios.get(url, { params: params })
                           .then(function(results) {
                             return results.data
                           })
                           .finally(() => {
                             PENDING_REQUESTS--
                           })
        resolve(results)
      }
    }, 10)
  })
}

// Public: Post data to a server.
//
// endpoint   - Post data to this URL.
// parameters - Object containing parameters for the request.
//
// Returns a Promise that resolves to the data returned from the server.
export function post(url, params) {
  return axios.post(url, params)
              .then(function(results) {
                return results.data
              })
              .catch((results) => {
                return Promise.reject(results.response.data)
              })
}

// Public: Post data to a server with form data.
//
// endpoint   - Post data to this URL.
// parameters - Object containing parameters for the request.
//
//
// Returns a Promise that resolves to the data returned from the server.
export function postForm(url, params) {
  return axios({
    method:  'post',
    url:     url,
    data:    serialize(params),
    headers: {'Content-Type': 'multipart/form-data' }
  })
}

// Public: Decorative wrapper around axios.put to keep the API the same as the
// existing i4cp async function.
//
// url    - Endpoint URL.
// params - Querystring parameters.
//
// Returns a Promise.
export function put(url, params) {
  return axios.put(url, params)
              .then(function(results) {
                return results.data
              })
}
