// Public: Post model.
//
// options - Object containing Post properties (default: {}).
//           :answered            - returns whether or not the post has been answered since the model was loaded
//           :answers             - array of answer posts
//           :associations        - Groups (KCs, Boards, Exchanges, etc.) the Post is associated with (default: []).
//           :assetfileName       - Filename of the Post asset (default: '').
//           :assetUpdatedAt      - Date the asset was last updated
//           :body                - Body of the Post (default: '').
//           :categories          - Categories the Post belongs to (default: []).
//           :children            - Child Posts (default: { items: [], total: 0, page: 1, perPage: 10 }).
//           :enRecipientcount    - number of recipients of expert network messages for the post
//           :id                  - Id of the Post (default: '').
//           :isPrivate           - Whether or not the Post is private (default: false).
//           :keywords            - important terms extracted from the post body
//           :markedForDeletion   - Whether or not the post has a deleted date (default: false).
//           :meetingAt           - time the meeting takes place
//           :member              - Member associated with the Post (default: models.User()).
//           :parentId            - Id of the post's parent post
//           :permalink           - Permalink of the Post (default: '').
//           :publishedAt         - When the Post was published (in YYYYMMDD-HHMMSS-NN format) (default: '').
//           :restricted          - Whether or not the content of the Post is restricted for the current member (default: false).
//           :subject             - Subject of the Post (default: '').
//           :tags                - Array of tag names for the Post (default: []).
//           :thumbnailClass      - Extra CSS class(es) to apply to the Post thumbnail image (default: '').
//           :thumbnailUrl        - Url of the Post thumbnail (default: '').
//           :type                - Type of the Post (default: 'Post').
//
// Returns a new Post object.
import map                         from 'lodash/map'
import isString                    from 'lodash/isString'
import flatten                     from 'lodash/flatten'
import merge                       from 'lodash/merge'
import flattenDeep                 from 'lodash/flattenDeep'
import intersection                from 'lodash/intersection'
import {blank, present, toBoolean} from '../lib/utils.js'
import {wordCount}                 from '../lib/string.js'
import userModel                   from './user.js'
import associationModel            from './association.js'

let postModel = function(options) {
  if (blank(options)) {
    options = {};
  }

  var defaultPost   = {
    answered:            false,
    answers:             [],
    associations:        [],
    assetFileName:       '',
    assetUpdatedAt:      null,
    body:                '',
    categories:          [],
    children: {
      items:   [],
      total:   0,
      page:    1,
      perPage: 10
    },
    enRecipientCount: 0,
    expiresAt:           null,
    keywords:            '',
    id:                  '',
    isPrivate:           false,
    markedForDeletion:   false,
    meetingAt:           '',
    member:              userModel(),
    parentId:            null,
    permalink:           '',
    // In YYYYMMDD-HHMMSS-NN format.
    publishedAt:         '',
    mentions:            [],
    restricted:          false,
    // In YYYYMMDD-HHMMSS-NN format.
    startAt:             '',
    subject:             '',
    tags:                [],
    thumbnailClass:      '',
    thumbnailUrl:        '',
    type:                'Post'
  };
  var post = merge({}, defaultPost, options);

  if (present(options.children)) {
    post.children = merge({}, options.children);
  }

  // convert answers into post models
  if (present(options.answers)) {
    post.answers = options.answers.map(function(answer) { return postModel(answer); });
  }

  post.associations = map(options.associations, function(item) {
                        return isString(item) ? associationModel( {code: item}) : item;
                      });

  // Coerce properties into booleans.
  post.isPrivate         = toBoolean(post.isPrivate);
  post.markedForDeletion = toBoolean(post.markedForDeletion);
  post.restricted        = toBoolean(post.restricted);

  return post;
}

// Public: return whether or not the post body is present
//
// Determine the presence of the body of this post
postModel.bodyPresent = function(post, minWords) {
  return present(post.body) && wordCount(post.body) >= minWords;
}

// Public: Get an array of category codes for the Post.
//
// post - A models.Post object.
//
// Returns an array of strings.
postModel.categoryCodes = function(post) {
  let results = map(post.categories, 'code')

  return flatten(results)
}

// Public: return whether or not the post subject is present
//
// Determine the presence of the body of this post
postModel.subjectPresent = function(post, minWords) {
  return present(post.subject) && wordCount(post.subject) >= minWords;
}

// Public: Convert a Post with Ruby-specific attributes to one with Javascript-specific attributes.
//
// post - Convert the attributes for this Post.
//
// Returns a new models.Post.
postModel.fromRubyAttributes = function(post) {
  return postModel({
    answers:             map(post.answers, postModel.fromRubyAttributes),
    associations:        post.associations,
    assetFileName:       post.asset_file_name,
    assetUpdatedAt:      post.asset_updated_at,
    body:                post.body,
    categories:          post.categories,
    children:     {
      items:   map(post.children.items, postModel.fromRubyAttributes),
      total:   post.children.total,
      page:    post.children.page,
      perPage: post.children.per_page
    },
    enRecipientCount:    post.en_recipient_count,
    expiresAt:           post.expires_at,
    keywords:            post.keywords,
    id:                  post.id,
    isPrivate:           post.is_private,
    markedForDeletion:   post.marked_for_deletion,
    meetingAt:           post.meeting_at,
    member:              userModel.fromRubyAttributes(post.member),
    parentId:            post.parent_id,
    permalink:           post.permalink,
    publishedAt:         post.published_at,
    mentions:            post.mentions,
    restricted:          post.restricted,
    startAt:             post.start_at,
    subject:             post.subject,
    tags:                post.tags,
    thumbnailUrl:        post.thumbnail_url,
    type:                post.type
  });
};

// Public: Does the given models.Post have the given categories?
//
// post - A models.Post object.
// categories - Category codes (e.g., 'question', 'answer').
//
// Returns true if the models.Post has the given categories and false otherwise.
postModel.hasCategoryCodes = function(post, categoryCodes) {
  var postCategoryCodes = postModel.categoryCodes(post);
  categoryCodes         = flattenDeep([categoryCodes]);

  return present(intersection(postCategoryCodes, categoryCodes));
};

// Public: Is the given models.Post a discussion?
//
// post - A models.Post object.
//
// Returns true if the models.Post is a discussion and false otherwise.
postModel.isDiscussion = function(post) {
  return postModel.hasCategoryCodes(post, ['answer', 'discussion', 'question']);
};

// Public: Get the models.Post's member's company.
//
// post - A models.Post object.
//
// Returns a string.
postModel.memberCompany = function(post) {
  return post.member.company;
};

// Public: Get the full name for the models.Post's member.
//
// post - A models.Post object.
//
// Returns a string.
postModel.memberFullName = function(post) {
  return userModel.fullName(post.member);
};

// Public: Convert a Post with Javascript-specific attributes to one with Ruby-specific attributes.
//
// post - Convert the attributes for this Post.
//
// Returns a new models.Post.
postModel.toRubyAttributes = function(post) {
  return {
    answers:               map(post.answers, postModel.toRubyAttributes),
    associations:          post.associations,
    asset_file_name:       post.assetFileName,
    asset_updated_at:      post.assetUpdatedAt,
    body:                  post.body,
    categories:            post.categories,
    children:      {
      items:    map(post.children.items, postModel.toRubyAttributes),
      total:    post.children.total,
      page:     post.children.items.page,
      per_page: post.children.items.perPage
    },
    en_recipient_count:    post.enRecipientCount,
    expires_at:            post.expiresAt,
    keywords:              post.keywords,
    id:                    post.id,
    is_private:            post.isPrivate,
    marked_for_deletion:   post.markedForDeletion,
    member:                userModel.toRubyAttributes(post.member),
    parent_id:             post.parentId,
    permalink:             post.permalink,
    published_at:          post.publishedAt,
    mentions:              post.mentions,
    restricted:            post.restricted,
    start_at:              post.startAt,
    subject:               post.subject,
    tags:                  post.tags,
    thumbnail_url:         post.thumbnailUrl,
    type:                  post.type
  };
};

export default postModel;
