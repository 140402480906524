<!--
Rotation carousel banner for posts.

Example:
   <carousel items='<%= @announcements %>' type="post" autoplay=true></carousel>
-->
<template>
  <div class="carousel-wrapper">
    <button class="btn carousel-btn" @click="previous"><</button>
    <transition v-if="slides.length" name="carousel-fade" tag="div">
      <carousel-slide :type="type" :item="slides[index]"></carousel-slide>
    </transition>
    <button class="btn carousel-btn right-0" @click="next">></button>
  </div>
</template>

<script>
  import CarouselSlide from './carousel_slide.vue'

  export default {
    components: {
      CarouselSlide
    },

    props: {
      // Boolean whether to cycle through slides.
      autoplay: { default: false },
      // Integer for miliseconds to remain on each slide.
      interval: { default: 15000 },
      // String of items passed from Rails template that will be converted to JSON array on load.
      items:    { default: '' },
      // The type of slide.
      type:     { default: 'post' }
    },

    data() {
      return {
        // Internal: Timer for playing through slides.
        timer: null,
        // Internal: Current slide.
        index: 0,
        // Internal: Array of Objects of posts.
        slides: []
      }
    },

    methods: {
      // Internal: Verifies component is passed array of objects.
      validateItems(items) {
        try {
          return Array.isArray(JSON.parse(items)) ? JSON.parse(items) : []
        } catch(error) {
          return []
        }
      },

      // Internal: Start slideshow.
      startSlide() {
        // We want to reset the interval if user has clicked `next` or `previous`.
        if (this.timer) clearInterval(this.timer)

        this.timer = setInterval(this.next, this.interval)
      },

      // Internal: Render next slide. If at end, return to the beginning.
      next() {
        this.startSlide()

        if (this.index === this.slides.length - 1) {
          this.index = 0
        } else {
          this.index += 1
        }
      },

      // Internal: Render previous slide. If at beginning, go to last slide.
      previous() {
        this.startSlide()

        if (this.index === 0) {
          this.index = this.slides.length - 1
        } else {
          this.index -= 1
        }
      }
    },

    mounted() {
      // Rails is passing this as a string. Convert to JSON array.
      this.slides = this.validateItems(this.items)

      if (this.autoplay) {
        this.startSlide()
      }
    }
  }
</script>
