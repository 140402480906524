<!-- Display a checkbox that toggles the visibility of render tags -->
<template>
  <label class="display-flex justify-left align-items-center pointer">
    <input type    = "checkbox"
           v-model = "showTags"
           @change = "toggleRenderTags"/>
    <div class="pl-10 pt-4 font-weight-normal">Show render files</div>
  </label>
</template>

<script>
  import each      from 'lodash/each'
  import {present} from '../lib/utils.js'

  export default {
    data() {
      return {
        // Toggle switch - true to show the tags.
        showTags: false
      }
    },

    methods: {
      // Remove the dev-tools-renders class from all dom elements in
      // the devtools panel.
      removeRenderClassFromDevtools() {
        let elements = document.getElementsByClassName('devtools')[0]

        if (present(elements)) {
          elements = elements.querySelectorAll("*")

          each(elements, (element) => {
            element.classList.remove('dev-tools-renders')
          })
        }
      },

      // Toggle the display of render tags.
      toggleRenderTags(e) {
        let elements = document.getElementsByClassName('dev-tools-renders')

        each(elements, (element) => {
          if (this.showTags) {
            element.style.display = 'block'
          }
          else {
            element.style.display = 'none'
          }
        })
      }
    },

    mounted() {
      // No need to show render tags of the devtools panel.
      this.removeRenderClassFromDevtools()
    }
  }
</script>
