<!--
Public: Create a tile to link to a post. The tile then gets displayed on a collection page or a front page

Properties:
  subject -   Subject of post
  permalink - Local URL of post
  imageUrl -  URL to access tile image
  imageName - Name of tile image
-->

<template>
  <div class="col-lg-4 col-md-6 mb-sm-50">
    <a :href="permalink" class="text-decoration-none-hover">
      <div class="pv-5">
        <div class="item-grid-item bt-1 border-marketing-lightest-gray box-shadow-panel border-radius-3 mv-10 background-color-lightest-gray">
          <div class="item-grid-image overflow-hidden position-relative">
            <img :alt="imageName" :src="imageUrl" class="width-100p display-block"/>
          </div>
          <div class="pa-10">
            <div class="block-margin-0 overflow-hidden h-6em line-height-1-point-5em display-flex">
              <post-lock-icon :permalink="permalink" >
              </post-lock-icon>
              <div class="text-decoration-underline-hover color-dark-blue">{{ subject }}</div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
  import PostLockIcon from '../components/post_lock_icon.vue'

  export default {
    components: {
      PostLockIcon
    },

    props: {
      // Subject of post
      subject: { default: '' },
      // Local URL of post
      permalink: { default: '' },
      // URL to access tile image
      imageUrl: { default: '' },
      // Name of tile image
      imageName: { default: '' }
    }
  }
</script>
