<template>
  <div :class="cssClass">
    <div v-html="displayedText"></div>
    <a v-show="hasLongText" @click="expanded = !expanded" class="pointer-on-hover">
      {{ postfix }}
    </a>
  </div>
</template>

<script>
  export default {
    props: {
      // CSS classes to apply to the div.
      cssClass:       { default: '' },
      // The full text to render.
      text:           { default: '' },
      // The number of characters to display on render.
      charsToDisplay: { default: 400 }
    },

    data: function() {
      return {
        // Public: Whether the component is expanded to show the full text.
        expanded: false
      }
    },

    computed: {
      // Public: The text displayed by the component.
      displayedText() {
        const abbreviated = this.text.slice(0, this.charsToDisplay) + '...'

        return (this.expanded || !this.hasLongText) ? this.text : abbreviated
      },
      // Public: Whether the provided text is longer than the limit.
      hasLongText() {
        return this.text.length >= this.charsToDisplay
      },
      // Public: Content of show more/less anchor.
      postfix() {
        return this.expanded ? 'Hide' : 'Read more'
      }
    }
  }
</script>
