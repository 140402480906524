<!-- Public: Twitter timeline component -->

<template>
  <div>
    <a
      class="twitter-timeline"
      data-width="242"
      data-dnt="true"
      data-theme="light"
      data-link-color="#00aed9"
      :href="url">
      {{text}}
    </a>
    <forgiving-script
      src     = "https://platform.twitter.com/widgets.js"
      charset = "utf-8"
      async>
    </forgiving-script>
  </div>
</template>

<script>
  import ForgivingScript from './forgiving_script.vue'

  export default {
    components: {
      ForgivingScript
    },

    props: {
      text: { default: '' },
      url:  { default: '' }
    }
  }
</script>