// Add elementId and elementName computed fields to detemine the component
// id and name based on the entity and field attributes
//
// Properties
//   entity - Underscored object class name (blog_post, interview, archive_document) used to calculate the id and name attributes
//   field  - Used to calculate the id and name attributes (default: null).
//   id     - Id attribute (default: null).
//   name   - Name attribute (default: null).
//
// Computed Properties
//   elementId     - Calculate the id attribute based on the entity and field attributes.
//   elementName   - Calculate the name attribute based on the entity and field attributes.
//   missingFields - Return true if the id is not specified and either the entity or field attriubtes are missing and false otherwise.
//
// Events
//   element-id-and-name:update-entity - Event listener to update the entity property. This indirectly updates
//                                       computed fields such as elementId and elementName
//
// Examples
//
//   If the entity is 'blog_post' and the field is 'subject', then calculated
//   element id is blog_post_subject and the calculated name is blog_post[subject]
import { blank, present } from '../lib/utils.js'

export default {
  props: {
    // Public: Underscored object class name (blog_post, interview, archive_document) used to calculate the id and name attributes
    entity:  { default: null },
    // Public: Used to calculate the id and name attributes (default: null).
    field:   { default: null },
    // Public: Id attribute (default: null).
    id:      { default: null },
    // Public: Whether the field is an array.
    isArray: { default: false },
    // Public: Name attribute (default: null).
    name:    { default: null }
  },

  computed: {
    // Public: Calculate the id attribute based on the entity and field attributes.
    //
    // Returns a string.
    elementId() {
      if (this.missingFields) return null

      return present(this.id) ? this.id : this.entity + '_' + this.field
    },

    // Public: Calculate the name attribute based on the entity and field attributes.
    //
    // Returns a string.
    elementName() {
      if (this.missingFields) return null

      const name = present(this.name) ? this.name : `${this.entity}[${this.field}]`

      return this.isArray ? `${name}[]` : name
    },

    // Public: Get the element for this mixin.
    //
    // Returns a jQuery object.
    input() {
      return $('#' + this.elementId)
    },

    // Public: Return true if the id is not specified and either the entity or field attriubtes are missing and false otherwise.
    //
    // Returns true if the id is not specified and either the entity or field attriubtes are missing and false otherwise.
    missingFields() {
      return blank(this.id) && (blank(this.entity) || blank(this.field))
    }
  },

  events: {
    // Internal: Event listener to update the entity property. This indirectly updates
    // computed fields such as elementId and elementName
    //
    // data - parameter hash
    // source: component that broadcast this event
    // entity: new entity to replace the old
    'element-id-and-name:update-entity': function(data) {
      this.entity = data.entity
    }
  }
}
