<!--
Material design styled checkbox to replace standard HTML checkbox.

Example:
<material-checkbox
  id    = "id"
  name  = "name"
  value = "value">
</material-checkbox>
-->

<template>
  <div class="material-checkbox">
    <icon :name  = "iconName"
          :sub   = "iconSub"
          @click = "selectValue"
          :class = "cssClasses">
    </icon>

    <input type     = "hidden"
           :id      = "id"
           :name    = "name"
           :value   = "internalValue"
           :checked = "internalChecked" />
  </div>
</template>

<script>
  import Icon from './icon.vue'
  import { present, toBoolean } from '../lib/utils.js'

  export default {
    components: { Icon },

    props: {
      // Prop whether the box is checked at the beginning.
      checked:  { default: false },
      // Extra CSS class(es) to add to the element.
      cssClass: { default: '' },
      // Passed along to the internal input attribute for posting.
      id:       { default: '' },
      // Passed along to the internal input attribute for posting.
      name:     { default: '' },
      // When given, update the object's value property to the internalValue.
      object:   { default: null },
      // Passed along to the internal input attribute for posting.
      value:    { default: '' }
    },

    data() {
      return {
        // Internal: whether the checkbox should display as checked or not
        // Use of eval() in cases where this.checked is passed in as a string
        internalChecked: toBoolean(this.checked)
      }
    },

    computed: {
      // Internal: modify the icon displayed based on whether the component is checked
      iconName() {
        return this.internalChecked ? 'check-square' : 'square'
      },

      // Internal: modify the icon displayed based on whether the component is checked.
      // Required because the 'square' icon in fontawesome defaults to solid instead of
      // hollow
      iconSub() {
        return this.internalChecked ? 'solid' : 'regular'
      },

      // Internal: Update the input's value based on the checked status.
      internalValue() {
        return this.internalChecked ? '1' : '0'
      },

      // Internal: build css values based on params and data items.
      cssClasses() {
        const iconColor = this.internalChecked ? 'color-material-blue' : 'color-gray'

        return `material-checkbox ${this.cssClass} ${iconColor}`
      }
    },

    watch: {
      // Update the object's value property to match internalChecked
      internalChecked(newValue, OldValue) {
        if (present(this.object)) {
          this.object.value = newValue
        }
      },

      // Watches 'checked' prop for changes to ensure updates to internalChecked
      checked(newValue, oldValue) {
        this.internalChecked = toBoolean(newValue)
      }
    },

    methods: {
      // Internal: sets the checked attribute to its opposite on user click
      selectValue() {
        this.internalChecked = !this.internalChecked
        this.$emit('click', this.value)
      }
    }
  }
</script>
