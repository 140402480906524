<!--
Show a collapsible list of years. Each years links to to the given path.
-->
<template>
  <div class="pl-65 pb-20 font-1-point-1em">
    <div v-for="year in recentYears">
      <a :href="linkPath(year)" :class="yearClass(year)">{{year}}</a>
    </div>

    <div refs="more" v-show="expanded">
      <div v-for="year in olderYears">
        <a :href="linkPath(year)" :class="yearClass(year)">{{year}}</a>
      </div>
    </div>

    <a v-show = "!expanded"
       @click = "showMoreClicked = true"
       class  = "pointer">
      more
    </a>
  </div>
</template>

<script>
  import includes    from 'lodash/includes'
  import {toBoolean} from '../lib/utils.js'

  export default {
    props: {
      // comma-separated list of years.
      years:        { default: [] },
      // Highlight this year.
      selectedYear: { default: null },
      // Url for each year link.
      path:         { default: ''}
    },

    data() {
      return {
        // Internal: Convert years string into an array
        internalYears:   this.years.split(/,\s*/),
        // Internal: Changes to true when the more link is clicked
        showMoreClicked: false
      }
    },

    computed: {
      // Internal: True if the selected year is in the older years list of the
      // more link has been clicked
      expanded() {
        return includes(this.olderYears, this.selectedYear) || this.showMoreClicked
      },

      // Internal: Return a list of more distant years
      olderYears() {
        return this.internalYears.slice(4, this.internalYears.length)
      },

      // Internal: Return a list of recent years
      recentYears() {
        return this.internalYears.slice(0, 4)
      }
    },

    methods: {
      // Internal: Bold the selected year
      yearClass(year) {
        return year == this.selectedYear ? 'bold' : ''
      },

      // Internal: Calcualte the year link paths
      linkPath(year) {
        return `${this.path}?date=${year}`
      }
    }
  }
</script>
