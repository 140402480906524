/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require('intersection-observer');

import "core-js/stable"
import "regenerator-runtime/runtime"

import "chartkick/chart.js"

import Vue                  from 'vue/dist/vue.esm.js'
import Vuex                 from 'vuex'
import {store}              from '../lib/marketing_store.js'

import AccentText           from '../components/accent_text.vue'
import Alert                from '../components/alert.vue'
import AttachmentsDropdown  from '../components/attachments_dropdown.vue'
import BenchmarkRawData     from '../components/benchmark/benchmark_raw_data.vue'
import Carousel             from '../components/carousel.vue'
import CarouselSlide        from '../components/carousel_slide.vue'
import ContactFormSubmit    from '../components/contact_form_submit.vue'
import CookieBanner         from '../components/cookie_banner.vue'
import CountryDropdown      from '../components/country_dropdown.vue'
import ForgivingScript      from '../components/forgiving_script.vue'
import FormattedTime        from '../components/formatted_time.vue'
import Impersonate          from '../components/impersonate.vue'
import MaterialCheckbox     from '../components/material_checkbox.vue'
import MaterialRadio        from '../components/material_radio.vue'
import MaterialTabs         from '../components/material_tabs.vue'
import MobileAppBanner      from '../components/mobile_app_banner.vue'
import PhoneInput           from '../components/phone_input.vue'
import PopupError           from '../components/popup_error.vue'
import PopupMessage         from '../components/popup_message.vue'
import Icon                 from '../components/icon.vue'
import InputText            from '../components/input_text.vue'
import LazyImageLoader      from '../components/lazy_image_loader.vue'
import Modal                from '../components/modal.vue'
import NewsletterPopup      from '../components/newsletter_popup.vue'
import Podcast              from '../components/podcast.vue'
import PostTile             from '../components/post_tile.vue'
import PressYears           from '../components/press_years.vue'
import SavePost             from '../components/save_post.vue'
import SidePanel            from '../components/side_panel.vue'
import SimpleSearch         from '../components/simple_search.vue'
import TextPreview          from '../components/text_preview.vue'
import ToggleRenderTags     from '../components/toggle_render_tags.vue'
import Twitter              from '../components/twitter.vue'
import VideoBase            from '../components/video_base.vue'
import Youtube              from '../components/youtube.vue'

document.addEventListener('DOMContentLoaded', () => {
  Vue.use(Vuex);

  const app = new Vue({
    el: '.marketing-content',
    components: {
      AccentText,
      Alert,
      AttachmentsDropdown,
      BenchmarkRawData,
      ContactFormSubmit,
      Carousel,
      CarouselSlide,
      CookieBanner,
      CountryDropdown,
      ForgivingScript,
      FormattedTime,
      Icon,
      InputText,
      Impersonate,
      LazyImageLoader,
      MaterialTabs,
      MaterialCheckbox,
      MaterialRadio,
      MobileAppBanner,
      Modal,
      NewsletterPopup,
      PhoneInput,
      PopupError,
      PopupMessage,
      Podcast,
      PostTile,
      PressYears,
      SavePost,
      SidePanel,
      SimpleSearch,
      TextPreview,
      ToggleRenderTags,
      Twitter,
      VideoBase,
      Youtube
    },
    store: store()
  })
})
