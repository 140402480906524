// Public: User model.
//
// options - Object containing User properties (default: {}).
//           :avatarUrl          - URL of the User's avatar (default: userModel.genericAvatarUrl).
//           :company            - Company the User belongs to (default: '').
//           :firstName          - The User's first name (default: '').
//           :interests          - The User's selected interests (default: []).
//           :isContentManager   - True if the User is a content manager and false otherwise (default: false).
//           :isGuest            - True if the User is a guest and false otherwise (default: false).
//           :isPrivate          - True if the User's anonymous setting is true (default: false).
//           :isSysadmin         - True if the User is a system admin and false otherwise (default: false).
//           :lastName           - The User's last name (default: '').
//           :linkedinProfileUrl - The User's public Linkedin profile URL (default: '').
//           :memberId           - The User's member id (default: '').
//           :primaryGroupId     - Id of the User's primary group (default: null).
//           :title              - The User's job title (default: '').
//           :username           - The User's username (default: '').
//           :workPhone          - The User's work phone number (default: '').
//
// Returns a new User object.
import map                from 'lodash/map'
import includes           from 'lodash/includes'
import merge              from 'lodash/merge'
import {blank, toBoolean} from '../lib/utils.js'
import interestModel      from './interest.js'

const GENERIC_AVATAR_URL = 'https://content.i4cp.com/images/image_uploads/0000/3187/default-avatar.png'

let userModel = function(options) {
  var defaultUser       = {
    avatarUrl:          GENERIC_AVATAR_URL,
    company:            '',
    firstName:          '',
    interests:          [],
    isContentManager:   false,
    isGuest:            false,
    isPrivate:          false,
    isSysadmin:         false,
    lastName:           '',
    linkedinProfileUrl: '',
    memberId:           '',
    primaryGroupId:     null,
    title:              '',
    username:           '',
    workPhone:          ''
  };
  var user              = merge({}, defaultUser, options);
  // I need to coerce isGuest and isPrivate into a boolean.
  user.isContentManager = toBoolean(user.isContentManager);
  user.isGuest          = toBoolean(user.isGuest);
  user.isPrivate        = toBoolean(user.isPrivate);
  user.isSysadmin       = toBoolean(user.isSysadmin);
  // Replace the default '<replace>' name with ''.
  user.firstName        = blank(user.firstName) ? '' : user.firstName.replace(/<replace>/g, '');
  user.lastName         = blank(user.lastName) ? '' : user.lastName.replace(/<replace>/g, '');
  user.interests        = map(user.interests, interestModel);

  return user;
};

userModel.genericAvatarUrl = GENERIC_AVATAR_URL;

// Public: The generic avatar URL.
userModel.fullName = function(user) {
  return user.firstName + ' ' + user.lastName;
};

// Public: Convert a User with Ruby-specific attributes to one with Javascript-specific attributes.
//
// user - Convert the attributes for this User.
//
// Returns a new userModel.
userModel.fromRubyAttributes = function(user) {
  return userModel({
    avatarUrl:          blank(user.avatar_url) ? userModel.genericAvatarUrl : user.avatar_url,
    company:            user.primary_group_name,
    firstName:          user.first_name,
    interests:          user.interests,
    isContentManager:   includes(user.roles, 'content-manager'),
    isGuest:            user.is_guest,
    isPrivate:          user.is_private,
    isSysadmin:         user.is_sysadmin,
    lastName:           user.last_name,
    linkedinProfileUrl: user.linkedin_profile_url,
    memberId:           user.id,
    primaryGroupId:     user.primary_group_id,
    title:              user.title,
    username:           user.username,
    workPhone:          user.work_phone
  });
};

// Public: Does the given userModel have admin access?
//
// user - A userModel object.
//
// Returns true if the given user has admin access and false otherwise.
userModel.hasAdminAccess = function(user) {
  return user.isSysadmin || user.isContentManager;
};

// Public: Convert a User with Javascript-specific attributes to an object with Ruby-specific attributes.
//
// user - Convert the attributes for this User.
//
// Returns an object.
userModel.toRubyAttributes = function(user) {
  return {
    avatar_url:           user.avatarUrl,
    primary_group_name:   user.company,
    first_name:           user.firstName,
    interests:            user.interests,
    is_guest:             user.isGuest,
    is_private:           user.isPrivate,
    last_name:            user.lastName,
    linkedin_profile_url: user.linkedinProfileUrl,
    member_id:            user.memberId,
    primary_group_id:     user.primaryGroupId,
    title:                user.title,
    username:             user.username,
    work_phone:           user.workPhone
  };
};

export default userModel;
