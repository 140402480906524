<template>
  <div v-if="showBanner" :class="`display-flex pa-15 width-100p ${cssClass}`">
    <div class="display-flex align-items-center">
      <img :src="deviceLogo" :alt="deviceAlt" class="h-50" />
    </div>
    <div class="pl-20">
      <div>i4cp Insights Mobile App</div>
      <div>i4cp</div>
      <div>FREE - in the App Store</div>
    </div>
    <div class="margin-left-auto">
      <div class="font-1-point-2em pull-right mb-10">
        <a @click="close" class="color-dark-gray">
          <icon name="times-circle"></icon>
        </a>
      </div>
      <div>
        <a @click="navigateToStore" class="button-primary btn ph-12 pv-4 background-color-marketing-blue color-white">
          Install
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import Icon     from './icon.vue'
  import { post } from '../lib/async.js'
  import {
    blank,
    device,
    isMobile,
    toBoolean
  }               from '../lib/utils.js'

  export default {
    components: { Icon },

    props: {
      // Public: CSS wrapper class
      cssClass: { default: '' },
      // Public: Current user's ID.
      userId:   { default: null }
    },

    data() {
      return {
        // Public: Whether the user has closed the banner.
        bannerClosed: false,
        // Public: Navbar element
        navbar:       null,
      }
    },

    computed: {
      device:   device,
      isMobile: isMobile,

      // Internal: `alt` attribute, depending on the device.
      deviceAlt() {
        return `${this.device} logo`
      },

      // Internal: URL for device's app store logo.
      deviceLogo() {
        if (this.isAppleAppStore)   return 'https://content.i4cp.com/images/image_uploads/0000/3712/apple_app_store.png'
        if (this.isGooglePlayStore) return 'https://content.i4cp.com/images/image_uploads/0000/3713/google_play_store.png'
      },

      // Internal: Calculate URL for app store based on device.
      deviceStoreUrl() {
        if (this.isAppleAppStore)   return 'https://apps.apple.com/us/app/i4cp-insights/id1382247140'
        if (this.isGooglePlayStore) return 'https://play.google.com/store/apps/details?id=com.i4cp.Insights'

        return 'https://www.i4cp.com/mobile'
      },

      // Internal: Check local storage to see if User has previously dismissed banner.
      hasDismissed() {
        return localStorage.getItem('i4cp-mobile-banner')
      },

      // Internal: Return true if this is an apple device
      isAppleAppStore() {
        return /iOS|iPadOS/.test(this.device)
      },

      // Internal: Return true if this is an android device
      isGooglePlayStore() {
        return this.device == 'Android'
      },

      // Internal: Only show banner if on mobile device & User hasn't previously clicked out of it.
      showBanner() {
        return this.isMobile && !this.bannerClosed
      }
    },

    methods: {
      // Public: Close popup, set as viewed, shift navbar to top.
      close() {
        this.bannerClosed = true

        this.setViewed()
        this.navbar.classList.add('navbar-fixed-top')
      },

      // Internal: Log that the User has navigated to the app store
      logAppClick() {
        if (blank(this.userId)) return

        const body = {
          user_id:    this.userId,
          device:     this.device,
          user_agent: navigator.userAgent
        }

        post('/api/metrics/visited-app-store', body)
          .then(() => {
            this.close()
          })
      },

      // Public: Go to the store specified for the User's device.
      //         We want to make that the user has clicked the banner, so don't use an `href`
      navigateToStore() {
        this.setViewed()
        this.logAppClick()

        this.$nextTick(() => {
          window.location.href = this.deviceStoreUrl
        })
      },

      // Public: Add to local storage that User has dismissed the banner.
      setViewed() {
        localStorage.setItem('i4cp-mobile-banner', true)
      }
    },

    mounted() {
      if (!isMobile()) return

      // Don't render banner if User has previously clicked it
      if (toBoolean(this.hasDismissed)) {
        this.bannerClosed = true

        return
      }

      // Push navbar below banner.
      const nav   = document.getElementsByClassName('navbar navbar-default')
      this.navbar = nav[0]
      this.navbar.classList.remove('navbar-fixed-top')
    }
  }

</script>
