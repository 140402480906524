<!--
Public: Display the number of characters in the given input

Data Properties
  dataMaxCount - Maximum allowable characters (default: 0).
  dataValue    - Current number of characters in the input (default: 0).

Properties
  maxCount - Maximum allowable characters (default: 0).
  value    - Current number of characters in the input (default: 0).

Computed Properties
  remaining - Calculate the number of remaining characters.

Events
  'text-counter:update'           - Change the class to a warning if the remaining characters has been exceeded
  'text-counter:change-max-count' - Change the maxiumum length of the input field.
-->

<template>
  <div :class="counterClass">Remaining characters:
    {{remaining}}
  </div>
</template>

<script>
  import ElementIdAndName from '../mixins/element_id_and_name.js'
  import {blank}          from '../lib/utils.js'

  export default {
    mixins: [ElementIdAndName],

    props: {
      // Maximum allowable characters (default: 0).
      maxCount: { default: 0 },
      // Current number of characters in the input (default: 0).
      value:    { default: 0 }
    },

    data() {
      return {
        // Public: Maximum allowable characters (default: 0).
        dataMaxCount: 0,
        // Public: Current number of characters in the input (default: 0).
        dataValue:    0
      }
    },

    computed: {
      // Public: Get the CSS class(es) for the counter.
      //
      // Returns a string.
      counterClass() {
        let classes = ['text-counter']

        if(this.remaining < 0) {
          classes.push('has-error')
        }

        return classes.join(' ')
      },

      // Pubilc: Calculate the number of remaining characters.
      //
      // Returns an integer.
      remaining() {
        return this.dataMaxCount - this.dataValue
      }
    },

    methods: {
      // Public: Change the class to a warning if the remaining characters has been exceeded
      //
      // data - parameter hash
      //        :source - source component
      //        :for    - id of the element to check
      //        :count  - the number of characters in the source element
      onTextCounterUpdate(data) {
        if(data.for == this.elementId) {
          this.dataValue = data.count
        }
      },

      // Public: Change the maxiumum length of the input field.
      //
      // data - parameter hash
      //        :for - element id string or regex partial element id
      onTextCounterChangeMaxCount(data) {
        if(blank(this.elementId)) {
          return
        }

        if(data.for == this.elementId || this.elementId.match(data.for)) {
          this.dataMaxCount = data.maxCount
        }
      }
    },

    created() {
      this.$root.$on('text-counter:update', this.onTextCounterUpdate)
      this.$root.$on('text-counter:change-max-count', this.onTextCounterChangeMaxCount)
    },

    mounted() {
      this.$nextTick(() => {
        this.dataMaxCount = this.maxCount
        this.dataValue    = this.value
      })
    }
  }
</script>
