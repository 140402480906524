<!--
Display a closable message - generally at the top of the page.
-->

<template>
  <div v-show   = "open"
       :class = "`alert block-margin-bottom-10 alert-dismissible fade in ${alertCss}`"
       role   = "alert">

    <a href           = ""
       type           = "button"
       class          = "close"
       data-dismiss   = "alert"
       aria-label     = "Close"
       @click.prevent = "onCloseClick">
      <span aria-hidden="true">&times;</span>
    </a>
    <slot>
    </slot>
  </div>
</template>

<script>
  import {blank, present} from '../lib/utils.js'

  export default {
    props: {
      // Type of message: danger, error, info, success
      type: { default: '' },
    },

    data() {
      return {
        // Internal: Show this component if true.
        open: true
      }
    },

    computed: {
      // Internal: CSS styling specific to the alert type.
      alertCss() {
        if (blank(this.type)) return ''

        return `alert-${this.type.toLowerCase()}`
      }
    },

    methods: {
      // Close button click event handler.
      onCloseClick() {
        this.open = false
      }
    }
  }
</script>
