<!--
Material design styled radio button to replace standard HTML radio button.

Example:
<material-radio id      = "id"
                name    = "name"
                value   = "value"
                checked = "<%= database_value == button_value %>">
</material-radio>
-->

<template>
  <span class="material-radio">
    <icon :name  = "iconName"
          sub    = "regular"
          @click = "selectValue"
          :class = "cssClasses">
    </icon>
    <input type      = "radio"
           class     = "display-none"
           :id       = "id"
           :name     = "name"
           :value    = "value"
           :checked  = "internalChecked" >
  </span>
</template>

<script>
  import Icon from './icon.vue'
  import { present, toBoolean } from '../lib/utils.js'

  export default {
    components: { Icon },

    props: {
      // Prop whether the box is checked at the beginning.
      checked:  { default: false },
      // Extra CSS class(es) to add to the element.
      cssClass: { default: '' },
      // Passed along to the internal input attribute for posting.
      id:       { default: '' },
      // Passed along to the internal input attribute for posting.
      name:     { default: '' },
      // When given, update the object's value property to the internalValue.
      object:   { default: null },
      // Passed along to the internal input attribute for posting.
      value:    { default: '' }
    },

    data() {
      return {
        // Internal: whether the checkbox should display as checked or not
        // Use of eval() in cases where this.checked is passed in as a string
        internalChecked: toBoolean(this.checked)
      }
    },

    computed: {
      // Internal: modify the icon displayed based on whether the component is checked
      iconName() {
        return this.internalChecked ? 'dot-circle' : 'circle'
      },

      // Internal: sets the color of the icon based on whether the button is checked
      cssClasses() {
        const iconColor = this.internalChecked ? 'color-material-blue' : 'color-gray'

        return `material-checkbox ${this.cssClass} ${iconColor}`
      },

      // Internal: Update the input's checked attribute based on the checked status.
      internalValue() {
        return this.internalChecked ? 'checked' : 'unchecked'
      },
    },

    methods: {
      // Internal: sets the checked attribute of this button to true while signaling
      // to other buttons in the group to false
      selectValue() {
        this.internalChecked = true
        this.$root.$emit('material-radio:click', this)
        this.$emit('click', this.value)
      },

      // Internal: sets the checked attribute of this button to false if another
      // button in the group gets set to true
      deselectValue(checkButton) {
        if(checkButton.name == this.name && checkButton != this) {
          this.internalChecked = false
        }
      }
    },

    watch: {
      // Update the object's value property to match internalChecked
      internalChecked(newValue, OldValue) {
        if (present(this.object)) {
          this.object.value = newValue
        }
      }
    },

    mounted() {
      this.$root.$on('material-radio:click', this.deselectValue)
    }
  }
</script>
