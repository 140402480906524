<!--
Public: Vue wrapper for lazy image loader.
        Removes 'lazy-image' class from all images on page, replaces `src` attribute
        with `data-src` attribute as elements scroll into view.

Example:
    <lazy-image-loader></lazy-image-loader>
-->
<template></template>

<script>
  export default {
    data() {
      return {
        // Public: Removes `lazy` class from observed DOM elements, then stops observing.
        imageObserver: new IntersectionObserver(this.observerCallback),
      }
    },

    computed: {
      // Public: Images to lazily load.
      lazyImages() {
        return [].slice.call(document.querySelectorAll('img.lazy'))
      }
    },

    methods: {
      // Internal: Callback function for the Intersection Observer instance.
      observerCallback(entries, observer) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.src = entry.target.dataset.src
            entry.target.classList.remove('lazy')
            entry.target.classList.add('lazy-loaded')
            observer.unobserve(entry.target)
          }
        })
      },

      // Public: Observes the images to be loaded.
      observeImages(images, observer) {
        images.forEach((image) => { observer.observe(image) })
      },
    },

    mounted() {
      // Does not work in IE
      if ('IntersectionObserver' in window) {
        document.addEventListener('scroll',          this.observeImages(this.lazyImages, this.imageObserver))
        window.addEventListener('resize',            this.observeImages(this.lazyImages, this.imageObserver))
        window.addEventListener('orientationChange', this.observeImages(this.lazyImages, this.imageObserver))
      }
    }
  }
</script>
