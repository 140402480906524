<template>
  <div :class="classes">
    <label class="sr-only" for="simple-autocomplete">{{ label }}</label>
    <input ref               = "input"
           id                = "simple-autocomplete"
           name              = "simple-autocomplete"
           type              = "text"
           autocomplete      = "off"
           autocorrect       = "off"
           aria-autocomplete = "both"
           aria-haspopup     = "false"
           :class            = "inputClass"
           :placeholder      = "placeholder"
           @keyup.down.stop  = "onDownArrow"
           @keyup.enter.stop = "onKeyEnter"
           @keyup.up.stop    = "onUpArrow"
           @keyup            = "onKeyup">
    </input>
    <div ref   = "dropdown"
         class = "simple-autocomplete-dropdown">
      <div v-for  = "item in items"
           :class = "rowClass(item)"
           @click = "onClick(item)">
        {{item.name}}
      </div>
    </div>
  </div>
</template>

<script>
  import each               from 'lodash/each'
  import { get }            from '../lib/async.js'
  import { blank, present } from '../lib/utils.js'

  export default {
    props: {
      // Class to apply to the input. (optional)
      cssClass:      { default: '' },
      // Api endpoint to call for data.
      endpoint:      { default: null },
      // Styling applied to the input element.
      inputClass:    { default: null },
      // Hidden label for screen-readers.
      label:         { default: '' },
      // Param name to pass into api call.
      parameterName: { default: null },
      // Text to show in input control when it has no value. (optional)
      placeholder:   { default: null },
      // Display text to show in input control. (optional)
      text:          { default: null }
    },

    data() {
      return {
        // Array of autocomplete data.
        items:            [],
        // Datetime of the last key up.
        lastTypedAt:      null,
        // Position of selected item.
        index:            -1,
        // Mutable input text.
        internalText:     this.text,
        // setInterval id of the aysnc typing code.
        typingIntervalId: null
      }
    },

    computed: {
      // Internal: Calculate the css class of the input element.
      classes() {
        return `${this.cssClass} simple-autocomplete h-33`
      },

      // Internal: Find the selected item in the list based on the index.
      selected() {
        if (this.items.length > 0) {
          return this.items[this.index]
        }

        return null
      }
    },

    methods: {
      // Public: Kind of hacky way to get the dropdown to close when the mouse
      // clicks out side this control. Would be better if vue had something
      // built-in for this scenario.
      documentClick(e) {
        // only close the dropdown if the click happens outside the component
        if (( this.$el !== e.target) && !this.$el.contains(e.target)) {
          this.hideDropdown()
        }
      },

      // Public: Focus on the input.
      focus() {
        this.$refs.input.focus()
      },

      // Internal: Get the results from the data call and apply it to the items data.
      getResults() {
        let parameters = {}
        parameters[this.parameterName] = this.internalText

        get(this.endpoint, parameters)
            .then(results => {
              this.items = results.items
              this.index = -1

              if (blank(this.$refs.input.value)) {
                this.hideDropdown()
              }
            })
      },

      // Internal: Return true if typing has occured in the last second.
      hasTypedInThelastSecond() {
        return (new Date() - this.lastTypedAt) <= 1000
      },

      // Internal: Hide the autocomplete dropdown.
      hideDropdown() {
        this.$refs.dropdown.style.display = 'none'
      },

      // Internal: Raise autocomplete dropdown click event.
      onClick(item) {
        this.$emit('click', item)
      },

      // Internal: Handle down arrow key press.
      onDownArrow(e) {
        this.lastTypedAt = null

        if (this.index >= this.items.length - 1) {
          this.index = -1
        }

        this.index = this.index + 1
        this.updateInputText()
      },

      // Internal: Raise autocomplete enter keypress event.
      onKeyEnter(item) {
        this.$emit('key-up-enter', item)
      },

      // Internal: Handle keyboard events.
      onKeyup(e) {
        if (e.code == 'ArrowDown' || e.code == 'ArrowUp' || e.code == 'Enter') {
          return
        }

        this.showDropdown()
        this.internalText = this.$refs.input.value
        this.lastTypedAt  = new Date()
      },

      // Internal: handle mouse enter event.
      onMouseOver(item) {
        // unhighlight the current row since the mouse over events will highlight the correct row.
        const rows = document.getElementsByClassName('simple-autocomplete-current')

        each(rows, (row) =>{
          row.classList.remove('simple-autocomplete-current')
        })
      },

      // Internal: Handle up arrow key press.
      onUpArrow(e) {
        this.lastTypedAt = null

        if (this.index <= 0) {
          this.index = this.items.length
        }

        this.index = this.index - 1
        this.updateInputText()
      },

      // Internal: Determine the css class for the given row.
      rowClass(item) {
        return item.name == this.internalText ? 'simple-autocomplete-row simple-autocomplete-current pointer' : 'simple-autocomplete-row pointer'
      },

      // Internal: Show the autocomplete dropdown.
      showDropdown() {
        this.$refs.dropdown.style.display = 'block'
      },

      // Internal: Start listening for typing events, update the dropdown results
      // if the user is typing.
      startTypingListener() {
        this.typingIntervalId = setInterval(() => {
          if(this.hasTypedInThelastSecond()) {
            this.getResults()
          }
        }, 500)
      },

      // Internal: Update the input value based on the selected item.
      updateInputText() {
        if (present(this.selected)) {
          this.internalText      = this.selected.name
          this.$refs.input.value = this.selected.name
        }
      }
    },

    mounted: function() {
      // Register this document click event handler to close the drop down when
      // the user clicks outside control.
      document.addEventListener('click', this.documentClick)

      // At this time, vue.js does not support mouseenter events, so do it the
      // jquery way instead.
      this.$refs.dropdown.addEventListener('mouseover', this.onMouseOver)

      this.startTypingListener()

      this.$refs.input.value = this.internalText
    }
  }
</script>
