<!--
Input entry to confirm a code texted to the user's phone.
-->
<template>
  <input
    ref        = "code"
    type       = "text"
    v-model    = "code"
    :class     = "[cssClass, 'font-2em', 'form-control']"
    :maxlength = "length"
    @keyup     = "onKeyUp"
    @keydown.enter.prevent
  />
</template>

<script>
  export default {
    props: {
      // Apply this css to the component.
      cssClass: { default: '' },
      // Allowable length of the code entered.
      length:   { default: 6 }
    },

    data() {
      return {
        // Confirmation code entered into the input.
        code: ''
      }
    },

    methods: {
      // Internal: Submit form when the code entered is long enough.
      onKeyUp() {
        if (this.code.length >= this.length) {
          this.$emit('confirm-code')
        }
      },

      // Public: Force focus to the input.
      refocus() {
        this.$refs.code.focus()
      }
    }
  }
</script>
