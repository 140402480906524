import Vuex      from 'vuex'

export function store() {
  return new Vuex.Store({
    state: {
      is_admin: false
    },

    mutations: {
    },

    actions: {
    }
  })
}
