<!--
     Display a panel to the side of the web page. The panel is locked
     to the right and overlaps page content beneath it.

     Attributes:
     title - Name desplayed at the top of the panel.

     Example:
     <side-panel ref="sidepanel" title="This is the side panel">
       Side panel content
     </side-panel>

     Notes:
     If this component is used in an ERB file, it is important to set
     the ref value, that way the panel can be opened like this:

     <some-element @click="$refs.the_ref_you_assigned_to_the_side_panel.show()"></some-element>
-->
<template>
  <div :class="`side_panel ${cssClasses}`">
    <div class="flex justify-between pb-1em">
      <div class="font-1-point-3em bold">
        {{title}}
      </div>

      <div class="flex">
        <!-- Future iteration -->
        <!-- <div class="pr-2em pointer flex align-items-center" @click="pin()">
             <icon name="thumbtack" type="fontawesome" css-class="color-white"></icon>
             </div> -->

        <div class="pointer flex align-items-center" @click="hide()">
          <icon name="times-circle" type="fontawesome" css-class="color-white"></icon>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
  import Icon from './icon.vue'

  export default {
    components: {
      Icon
    },

    props: {
      title: { default: '' }
    },

    data() {
      return {
        // Show this panel if true.
        display: false,
        pinned: false
      }
    },

    computed: {
      // Calculate the component's classes.
      cssClasses() {
        return `devtools
                position-fixed
                min-width-500
                max-width-500
                background-color-dark-gray
                color-light-gray
                pa-15
                bl-2
                b--black
                right-0
                top-0
                overflow-y-scroll
                thin-scroll-bar-dark
                height-100p ${this.display ? 'display-block' : 'display-none'}`
      }
    },

    methods: {
      // Toggle the display of this component.
      hide() {
        this.display = false
      },

      pin() {
      },

      // Toggle the display of this component.
      show() {
        this.display = true
      },
    }
  }
</script>

<style scoped>
  .side_panel {
    z-index: 9999999999 !important;
  }
</style>
