<!--
Show Raw JSON output of benchmark and current benchmark questionh data.
-->
<template>
  <div class="benchmark-raw-data">
    <div class="flex align-items-center pointer" @click="showBenchmark = !showBenchmark">
      <div><icon :name="showBenchmark ? 'caret-down' : 'caret-right'" type="fa" class="f1-2em w-10"></icon></div>
      <div class="bold pl-1em">Benchmark</div>
    </div>
    <div v-show="showBenchmark" class="json pl-1em" v-html="prettyPrint(benchmark)"></div>

    <div class="flex align-items-center pointer pt-1em" @click="showQuestion = !showQuestion">
      <div><icon :name="showQuestion ? 'caret-down' : 'caret-right'" type="fa" class="f1-2em w-10"></icon></div>
      <div class="bold pl-1em">Current Question</div>
    </div>
    <div v-show="showQuestion" class="json pl-1em" v-html="prettyPrint(currentBenchmarkQuestion)"></div>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import Icon from '../icon.vue'

  export default {
    components: {
      Icon
    },

    props: {

    },

    data() {
      return {
        // Toggle visibility of benchmark data.
        showBenchmark: false,
        // Toggle visibility of question data.
        showQuestion:  false
      }
    },

    computed: {
      ...mapState(['benchmark', 'currentBenchmarkQuestion']),
    },

    methods: {
      // Internal: Convert object into indented markup.
      prettyPrint(object) {
        return JSON.stringify(object, null, 2)
                   .replace(/\n/g, '<br/>')
                   .replace(/ /g, '&nbsp;')
      },
    },

    mounted() {
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/stylesheets/includes/_colors.scss";

  .json {
    font-family: 'Courier New', Courier, monospace;
  }
</style>
