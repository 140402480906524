<!--
Public: Harass guests to sign up for newsletter.

Example:
<newsletter-popup></newsletter-popup>
-->
<template>
  <modal ref='modal' modal-container-classes="max-width-500" @keyup.enter.native="subscribe">
    <div class="pb-5 mt-negative-10 mr-negative-10">
      <span @click="closeModal">
        <icon name="times" class="pull-right pointer-on-hover font-1-point-2em"></icon>
      </span>
    </div>
    <div>
      <h3 class="font-2-point-0em mb-15">
        Stay ahead of the curve.
      </h3>
      <h4 class="mb-20">
        Sign up to receive i4cp research & articles.
      </h4>
    </div>
    <div class="form-group">
      <input ref="email" type="email" class="form-control" placeholder="Email">
    </div>
    <div v-if="error" class="color-marketing-red mb-10">
      {{ error }}
    </div>
    <button type="submit" class="btn btn-primary" @click="subscribe">Subscribe</button>
  </modal>
</template>

<script>
  import moment       from 'moment'
  import Icon         from './icon.vue'
  import Modal        from './modal.vue'
  import { post }     from '../lib/async.js'
  import { isMobile } from '../lib/utils.js'

  const SUBSCRIBE_URL = "/api/crm/newsletter-subscribe"
  const WAIT_TIME     = 3000

  export default {
    components: {
      Icon,
      Modal
    },

    data() {
      return {
        // Internal: Whether there is an error with the email.
        error: null
      }
    },

    computed: {
      // Public: Whether the user has viewed newsletter modal.
      modalExpired() {
        const previous = moment(localStorage.getItem('signup'))

        if (previous._isValid == false) return true

        return previous < moment().subtract(2, 'days')
      }
    },

    methods: {
      // Internal: Close the modal.
      closeModal() {
        this.$refs.modal.hide()
      },

      // Internal: Render the modal.
      showModal() {
        // Make persist across site since component will be loaded fresh on each page view.
        localStorage.setItem('signup', moment()._d)

        this.$refs.modal.show()

        setTimeout(() => this.$refs.email.focus(), 1) // Hack to next cycle of event loop so the element exists.
      },

      // Public: Send user's email to the subscription endpoint and close the modal.
      subscribe() {
        if (this.$refs.email.value.length < 1) return

        post(SUBSCRIBE_URL, { email: this.$refs.email.value })
          .then(res => {
            window.location.href = '/company/downloads?signup=true'
          })
          .catch(err => {
            this.error = "Email is not valid."
          })
      },

      // Internal: Track user's mouse movements. Open modal if they approach top of page.
      trackMouse(e) {
        if (e.clientY < 100 && this.modalExpired) {
          window.removeEventListener('mousemove', this.trackMouse)

          this.showModal()
        }
      }
    },

    mounted() {
      if (isMobile() && this.modalExpired) {
        setTimeout(() => {
          this.showModal()
        }, WAIT_TIME)
      } else {
        setTimeout(() => {
          window.addEventListener('mousemove',this.trackMouse)
        }, WAIT_TIME)
      }
    }
  }
</script>
