<!--
Public: Banner alerting members and guests we use cookies.

Example:
  <cookie-banner user-id          = "<%= current_user.id %>"
                 accepted-cookies = "<%= current_user.accepted_cookies %>">
  </cookie-banner>
-->
<template>
  <div v-if="!hasAcceptedCookies" class="position-fixed bottom-0 width-100p background-color-lightest-gray pv-5 bt-1 border-color-light-gray z-index-top">
    <div class="container text-align-center vertical-align-middle">
      This website uses cookies for analysis, personalized content and advertising. By using this website you agree to this use.
      <a href="/privacy" target="_blank">Further information</a>
      <button @click="accept" class="btn btn-default btn-xs ml-5">I Agree</button>
    </div>
  </div>
</template>

<script>
  import { put }       from '../lib/async'
  import { toBoolean } from '../lib/utils'

  export default {
    props: {
      // Whether the current user has accepted cookies.
      acceptedCookies: { default: false },
      // The user's ID.
      userId:          { default: null }
    },

    data() {
      return {
        // Public: Whether to render the banner.
        viewedBanner: window.localStorage.getItem('viewedGdprBanner')
      }
    },

    computed: {
      // Internal: Whether the 'accept cookies' banner should be rendered.
      hasAcceptedCookies() {
        return toBoolean(this.acceptedCookies) || toBoolean(this.viewedBanner)
      }
    },

    methods: {
      // Public: Accept cookies and hide the banner.
      accept() {
        this.viewedBanner = true

        if (this.userId == null) {
          window.localStorage.setItem('viewedGdprBanner', true)
        } else {
          put('/api/members/accept_cookies', { id: this.userId })
        }
      }
    }
  }
</script>
