<!--
Formatted phone input. A confirmation code is sent the the number and the user
is prompted to enter that code.
-->
<template>
  <div>
    <div class="pb-20">
      <div v-show="confirmStep">
        <div>
          A message with a verification code has been texted to your cell phone.
          <p class="bold text-center color-dark-gray pt-30">
            Enter the code to continue
          </p>
        </div>
        <div class="text-center">
          <div class="display-flex justify-center">
            <div class="text-align-left">
              <phone-confirmation ref            = "code"
                                  :length        = "6"
                                  @confirm-code  = "confirmCode">
              </phone-confirmation>
              <div class="color-medium-red pt-5">{{message}}</div>
            </div>
            <div v-if="isProcessing" class="pl-10 display-flex align-items-center">
              <ajax-loading-icon></ajax-loading-icon>
            </div>
          </div>
        </div>
      </div>

      <div v-show="completeStep">
        <div class="pb-2em">{{completeText}}</div>
        <div class="display-flex justify-center font-1-point-3em">
          <div class="color-dark-gray pr-10">{{internalValue}}</div>
          <icon name="check" css-class="color-marketing-green"></icon>
        </div>
        <div class="color-medium-red pt-5">{{message}}</div>
      </div>

      <div v-show="updateStep">
        <div class="pt-3em pb-1em">{{updateText}}</div>
        <div class="display-flex align-items-center pt-1em">
          <input type   = "checkbox"
                 id     = "two_factor"
                 name   = "two_factor"
                 @click = "toggleValue"
                 checked
          />
          <label class="pl-1em font-weight-normal mb-0 pointer" for="two_factor">Use two-factor authentication</label>
        </div>
        <div class="color-medium-red pt-5">{{message}}</div>
      </div>

      <div v-show="inputStep || updateStep">
        <div v-show="inputStep" class="pb-2em">
          {{introText}}
        </div>
        <div class="display-flex justify-left">
          <!-- autocomplete="new-password" forces the input to not autocomplete other fields
               https://stackoverflow.com/questions/15738259/disabling-chrome-autofill -->
          <VuePhoneNumberInput ref                  = "phoneInput"
                               color                = "gray"
                               default-country-code = "US"
                               error-color          = "red"
                               valid-color          = "gray"
                               autocomplete         = "new-password"
                               v-model              = "internalValue"
                               :border-radius       = "0"
                               :no-example          = "true"
                               :preferred-countries = "['US']"
          />
          <button v-if           = "showButton"
                  :class         = "[buttonClasses, 'ml-10']"
                  @click.prevent = "sendCode">
            Send&nbsp;code
          </button>
        </div>
        <div class="color-medium-red pt-5">{{message}}</div>
      </div>
    </div>

    <input type="hidden" name="phone_number_confirmed" id="phone_number_confirmed" :value="completeStep"/>
    <input ref="hiddenInput" type="hidden" :id="id" :name="name"/>
  </div>
</template>

<script>
  import VuePhoneNumberInput from 'vue-phone-number-input'
  import 'vue-phone-number-input/dist/vue-phone-number-input.css'
  import {present}           from '../lib/utils.js'
  import {post}              from '../lib/async.js'
  import AjaxLoadingIcon     from './ajax_loading_icon.vue'
  import Icon                from './icon.vue'
  import PhoneConfirmation   from './phone_confirmation.vue'

  const CONFIRM_CODE_ENDPOINT = '/api/users/confirm-sms-code'
  const SEND_CODE_ENDPOINT    = '/api/users/send-sms-code'
  const UPDATE_PHONE_ENDPOINT = '/api/users/update-confirmation-phone'
  const SEND_LIMIT            = 3

  export default {
    components: {
      AjaxLoadingIcon,
      Icon,
      PhoneConfirmation,
      VuePhoneNumberInput
    },

    props: {
      // CSS applied to the send button.
      buttonClasses:        { default: '' },
      // CSS applied to this component
      classes:              { default: '' },
      // Text to show in the complete step.
      completeText:         { default: 'Your cell number has been confirmed.' },
      // Passed on to the phone input component
      id:                   { default: '' },
      // Text to display above the phone input. This text is replaced by
      // confirmation instructions after the code is sent.
      introText:            { default: null },
      // Passed on to the phone input component
      name:                 { default: '' },
      // Show the send code button if true
      showButton:           { default: true },
      // submit the form this control is in when the code is confirmed.
      submitOnConfirmation: { default: true },
      // Text to display at the top of the component in the update step.
      updateText:           { default: null },
      // Update this user's confirmation phone.
      userId:               { default: null },
      // Phone number.
      value:                { default: null }
    },

    data() {
      return {
        // Hashed security code.
        encryptedCode: null,
        // Internal phone number value.
        internalValue: this.value,
        // Show the spinner gif if true.
        isProcessing:  false,
        // Display message instead of top of screen message banner
        message:       null,
        // Hold onto the original value so it can be toggled.
        oldValue:      null,
        // name of copnfirmation step.
        step:          'input',
        // Number of times the code has been sent
        sendCount:     0
      }
    },

    computed: {
      completeStep() { return this.step == 'complete'},
      confirmStep()  { return this.step == 'confirm'},
      inputStep()    { return this.step == 'input'},
      updateStep()   { return this.step == 'update'},

      // Public: Returns the internationally formatted phone number.
      e164() {
        if (present(this.$refs.phoneInput)) {
          return this.$refs.phoneInput.results.e164
        }
      },

      // Internal: Show the code confirmation input control if true.
      showConfirmation() {
        return present(this.encryptedCode)
      }
    },

    methods: {
      // Internal: Confirm the code provided by the user.
      confirmCode() {
        this.isProcessing = true

        post(CONFIRM_CODE_ENDPOINT, { code: this.$refs.code.code, encrypted: this.encryptedCode })
          .then(isValid => {
            this.isConfirmed = isValid

            if (isValid) {
              this.$nextTick(_ => {
                this.$refs.hiddenInput.value = this.e164
                this.step = 'complete'

                if (this.submitOnConfirmation) {
                  this.$el.closest('form').submit()
                }
              })
            }
            else {
              this.isProcessing = false
              this.message = 'Code does not match'
            }
          })
      },

      // Internal: Send the code and code hash to the server for confirmation.
      sendCode() {
        if (this.sendCount >= SEND_LIMIT) return

        if (!this.$refs.phoneInput.isValid) {
          this.message = 'Phone number is not valid.'
          return
        }

        this.sendCount += 1

        post(SEND_CODE_ENDPOINT, { phone_number: this.e164 })
          .then(encryptedCode  => {
            this.encryptedCode = encryptedCode.code
            this.step = 'confirm'

            setTimeout(this.$refs.code.refocus, 100)
          })
          .catch(message => {
            this.message = `Unable to send confirmation code: ${message.error}`
          })
      },

      // Internal: Toggle the value data element based on the 2fa checkbox.
      toggleValue() {
        if (present(this.internalValue)) {
          this.oldValue                = this.internalValue
          this.internalValue           = null
          this.$refs.hiddenInput.value = null
        }
        else {
          this.internalValue           = this.oldValue
          this.$refs.hiddenInput.value = this.e164
        }
      }
    },

    watch: {
      step(newValue, OldValue) {
        // Clear out the message when the step changes
        this.message = ''

        // Async update the user's confirmation phone if a user id is given
        if (present(this.userId) && newValue == 'complete') {
          post(UPDATE_PHONE_ENDPOINT, { user_id: this.userId, confirmation_phone: this.e164 })
        }
      }
    },

    mounted() {
      if (present(this.internalValue)) {
        this.step = 'update'

        this.$nextTick(function() {
          this.$refs.hiddenInput.value = this.e164
        })
      }

      this.$root.$on('phone-input:send-code', this.sendCode);
    }
  }
</script>

<style lang="scss">
  .country-selector__label {
    top: 0px !important;
  }

  .input-tel__label {
    top: 0px !important;
  }
</style>
