<!--
     Public: Simple podcast component.
-->

<template>
  <audio
    id      = "podcast-audio"
    class   = "vjs-default-skin podcast-audio background-color-white width-100p h-3em"
    preload = "none"
    controls
  >
    <source :src="url" type="audio/mpeg" />
  </audio>
</template>

<script>
  export default {
    props: {
      // Public: The podcast's URL (default: '').
      url: { default: '' }
    }
  }
</script>
