<template>
  <span v-if="text.length > 0" class="accent-text">
    {{text}}
  </span>
</template>

<script>
  export default {
    props: {
      // Accent this text (default: '').
      text: { default: '' }
    }
  }
</script>
