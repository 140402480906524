<!--
Public: Component to display error messages.

Properties
  componentName - Name of the component (default: 'popupError').
  message       - Message to display (default: '').
  popupType     - Type of popup to display. This will be either 'alert-success' or 'alert-danger', depending on this.type.
  type          - Type of alert to display. Valid values are '', 'danger', and 'success' (default: 'danger').
  visible       - Whether or not the popup is visible (default: false).
-->

<script>
  import PopupMixin from '../mixins/popup_mixin.vue'

  export default {
    mixins: [PopupMixin],

    props: {
      // Name of the component (default: 'popupError').
      componentName: { default: 'popupError' },
      // Type of alert to display. Valid values are '', 'danger', and 'success' (default: 'success')
      type:          { default: 'danger' }
    },

    methods: {
      // Public: Hide the popup.
      onPopupErrorHide() {
        this.hide()
      },

      // Public: Display the popup.
      //
      // message - Message to display.
      // timeout - How long, in milliseconds, to display the popup (default: i4cp.Components.Vue.Mixins.PopupMixin.DEFAULT_TIMEOUT).
      //           If a value <= 0 is given the popup will only disappear if the "x" button is clicked.
      onPopupErrorShow(message, timeout) {
        this.show(message, timeout)
      }
    },

    created() {
      this.$root.$on('popup-error:hide', this.onPopupErrorHide)
      this.$root.$on('popup-error:show', this.onPopupErrorShow)
    }
  }
</script>
