<!--
Public: Simple AJAX loading icon component.

cssClass: CSS class(es) to apply to the component (default: '').
size:     small or large (default small)

Examples
  <ajax-loading-icon></ajax-loading-icon>
-->
<template>
  <span :class="classes">
    <img alt    = "Loading..."
         :class = "imgClass"
         :src   = "url"
    >
  </span>
</template>

<script>
  import { blank } from '../lib/utils.js'

  export default {
    props: {
      cssClass: { default: '' },
      imgClass: { default: '' },
      size:     { default: 'small' }
    },

    computed: {
      // Public: CSS class(es) applied to this component.
      classes() {
        let classes = [this.cssClass, 'ajax-loading-icon text-center']

        if (this.size == 'large') {
          classes.push('width-100p')
        }

        return classes.join(' ')
      },

      // Public: calculate the image url to use depending on the given size
      url() {
        if (blank(this.size) || this.size == 'small') {
          return 'https://content.i4cp.com/images/image_uploads/0000/4891/ajax_loading.gif'
        } else {
          return 'https://content.i4cp.com/images/image_uploads/0000/2001/hero_spinner.gif'
        }
      }
    }
  }
</script>
