// Public: Interest model.
//
// options - Object containing Interest properties (default: {}).
//           :checked - Whether or not the Profiles::Interest is checked (default: false).
//           :code    - The Profiles::Interest code (default: '').
//           :isShort - Whether or not the Profiles::Interest should be displayed on the short list (default: true).
//           :name    - The Profiles::Interest name (default: '').
//
// Returns an Interest. object.
import merge       from 'lodash/merge'
import {toBoolean} from '../lib/utils.js'

let interestModel = function(options) {
  var defaultInterest = {
    checked: false,
    code:    '',
    isShort: true,
    name:    ''
  };
  var interest     = merge(defaultInterest, options);
  // I need to coerce checked into a boolean.
  interest.checked = toBoolean(interest.checked);
  // I need to coerce isShort into a boolean.
  interest.isShort = toBoolean(interest.isShort);

  return interest;
}

// Public: Convert an Interest with Ruby-specific attributes to one with Javascript-specific attributes.
//
// interest - Convert the attributes for this Interest.
//
// Returns a new interestModel.
interestModel.fromRubyAttributes = function(interest) {
  return interestModel({
    checked: interest.checked,
    code:    interest.code,
    isShort: interest.is_short,
    name:    interest.name
  });
};

// Public: Convert an Interest with Javascript-specific attributes to one with Ruby-specific attributes.
//
// interest - Covnert the attributes for this Interest.
//
// Returns a new interestModel.
interestModel.toRubyAttributes = function(interest) {
  return {
    checked:  interest.checked,
    code:     interest.code,
    is_short: interest.isShort,
    name:     interest.name
  };
};

export default interestModel;
