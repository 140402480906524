<template>
  <div :class="simpleSearchClass">
    <simple-autocomplete ref            = "input"
                         endpoint       = "/api/autocomplete/suggest"
                         :input-class   = "borderRadii"
                         label          = "search"
                         parameter-name = "search"
                         :placeholder   = "placeholder"
                         :text          = "internalText"
                         @click         = "onClick"
                         @key-up-enter  = "onKeyUpEnter">
    </simple-autocomplete>
    <span v-show="showButton" class="input-group-btn">
      <button @click.prevent = "navigateToUrl(autocompleteText)"
              aria-label     = "search button"
              type           = "button"
              class          = "search-input-button btn btn-default h-33">
        <icon name="search"></icon>
      </button>
    </span>
  </div>
</template>

<script>
  import Icon               from '../components/icon.vue'
  import SimpleAutocomplete from '../components/simple_autocomplete.vue'
  import { blank, present } from '../lib/utils.js'

  export default {
    components: {
      Icon,
      SimpleAutocomplete
    },

    props: {
      // Public: Whether to automatically focus when rendered.
      autofocus:   { default: false },
      // Class to apply to the top element in the component.
      cssClass:    { default: '' },
      // Fontawesome or glyphicon.
      iconType:    { default: 'glyphicon' },
      // Placeholder text.
      placeholder: { default: 'Site Search' },
      // Querystring to apply to the search url.
      querystring: { default: '' },
      // True to display a button to the right of the search textbox.
      showButton:  { default: true },
      // Search terms.
      text:        { default: '' },
      // Redirect path for search results
      searchPath:  { default: '/search/' }
    },

    // internalText: mutable state for text property.
    // maxLength:    maximum search term length.
    data() {
      return {
        internalText: this.text,
        maxLength:    150
      }
    },

    computed: {
      // Public: Return the text in the autocomplete component.
      autocompleteText() {
        return this.$refs.input.internalText
      },

      // Public: Calculate the input border radius.
      borderRadii() {
        let cssClass = "border-top-left-radius-4 border-bottom-left-radius-4"

        if (!this.showButton) {
          cssClass = `${cssClass} border-top-right-radius-4 border-bottom-right-radius-4`
        }

        return cssClass
      },

      // Public: Css class to apply to the top level element.
      simpleSearchClass() {
        return `${this.cssClass} simple-search input-group input-group-sm font-0-point-8em`
      }
    },

    methods: {
      // Public: Navigate to the search url with the given query.
      navigateToUrl(query) {
        const searchValue = query

        if (blank(searchValue)) {
          return
        }

        let path = `${this.searchPath}${searchValue}`

        if (present(this.querystring)) {
          path = `${path}?${this.querystring}`
        }

        document.location.href = path
      },

      // Handle autocomplete click event.
      onClick(item) {
        this.navigateToUrl(item.value)
      },

      onKeyUpEnter(item) {
        this.navigateToUrl(this.autocompleteText)
        this.$refs.input.hideDropdown()

        // Turn off the auto complete typing checker.
        clearInterval(this.$refs.input.typingIntervalId)
      }
    },

    mounted() {
      if (this.autofocus) {
        this.$refs.input.focus()
      }
    }
  }
</script>
