<!--
Public: Format start and end times and localize to the users's browser.

Properties:
  Start_at    - String of the start date set to UTC.
  end_at      - String of the end date set to UTC.
  year_format - String: 'full', 'abbreviated', (default) ''
  with_day    - Boolean whether to render day.
  with_time   - Boolean whether to render time.
  full_month  - Boolean whether to render full month.

Computed:
  month     - String for moment.js to format month.
  time      - String for moment.js to format time.
  year      - String for moment.js to format year.
  start     - String representing datetime attribute of start <time> element.
  startHtml - String rendered as innerHTML of start <time> element.
  end       - String representing datetime attribute of end <time> element.
  endHtml   - String rendered as innerHTML of end <time> element.

Methods:
  sameDate  - Returns boolean whether two datetimes are same date.
  sameMonth - Returns boolean whether two datetimes are same month.

Example (dates can be hardcoded or populated via embeded Ruby):
<formatted-time start_at    = "<%= post.start_at %>"
                end_at      = "<%= post.end_at %>"
                timezone    = "<%= post.timezone %>"
                offset      = "<%= Auth::Timezone.find_by(code: post.timezone)[:timezone_offset].to_i %>"
                year_format = "full">
</formatted-time>
-->

<template>
  <span class="pr-10 bl-none pl-0">
    <span :class="`bl-none ${monthClasses}`">{{ dateHtml }}</span>
    <span :class="startClasses">
      <time :datetime      = "start"
            data-local     = "time"
            title          = start
            data-localized = "">{{ startHtml }}
      </time>
      {{ startHtml && endHtml ? "-" : "" }}
      <time :datetime      = "end"
            data-local     = "time"
            title          = end
            data-localized = "">{{ endHtml }}
      </time>
      {{ timeZone }}
    </span>
  </span>
</template>

<script>
  import * as moment                   from 'moment-timezone'
  import { blank, present, toBoolean } from '../lib/utils.js'

  export default {
    props: {
      // String of the start date set to UTC.
      start_at:     { default: '' },
      // String of the end date set to UTC.
      end_at:       { default: '' },
      // String year format.
      year_format:  { default: '' },
      // Boolean whether to render the day of week.
      with_day:     { default: false },
      // Boolean whether to render time.
      with_time:    { default: true },
      // Boolean whether to render full month or abbreviation.
      full_month:   { default: true },
      // Offset of timezone.
      offset:       { default: null },
      // Timezone of event.
      timezone:     { default: null },
      // CSS classes for start time.
      startClasses: { default: '' },
      // CSS classes for month.
      monthClasses: {default: '' },
      // Whether we want to display a compact date
      compact:      { default: false }
    },

    data() {
      return {
        railsDateFormat: 'YYYY-MM-DD HH:mm:ss Z'
      };
    },

    computed: {
      // Interal: If a timezone is supplied, calculate the difference btw it and the user's.
      difference() {
        return this.offset
          ? this.timeDifference(parseInt(this.offset), new Date().getTimezoneOffset())
          : 0
      },

      // Public: String for moment.js to format month.
      month() {
        return toBoolean(this.full_month) ? 'MMMM' : 'MMM'
      },

      // Public: String for moment.js to format time.
      time() {
        return toBoolean(this.with_time) ? ' h:mma' : ''
      },

      // Public: String for moment.js to format year.
      year() {
        if (this.year_format == 'full') {
          return ', YYYY'
        } else if (this.year_format == 'abbreviated') {
          return ", 'YY"
        } else {
          return ''
        }
      },

      // Public: Start date as a js date object
      start() {
        return present(this.start_at) ? moment.utc(this.start_at, this.railsDateFormat).local() : null
      },

      // Public: End date as a js date object
      end() {
        return present(this.end_at) ? moment.utc(this.end_at, this.railsDateFormat).local() : null
      },

      // Public: innerHtml of start date's <time> element
      startHtml() {
        if (!toBoolean(this.with_time)) {
          return ''
        }

        if (this.start == this.end || blank(this.end)) {
          return this.start.format(`${this.time}`)
        }

        if (this.sameDate && toBoolean(this.compact)) {
          // edge case: prevent hyphen between date and year
          return this.start.add(this.difference, 'hours').format(`${this.month} D${this.time}`)
        }

        if (this.sameDate()) {
          // edge case: prevent hyphen between date and year
          return this.start.add(this.difference, 'hours').format(`${this.time}`)
        }

        // No need to call `sameMonth()` for start date b/c display will be same regardless.
        return this.start.add(this.difference, 'hours').format(`${this.time}`)
      },

      // Public: innerHtml of end date's <time> element
      endHtml() {
        if ( blank(this.end) || (this.sameHours() && this.sameMinutes()) || !toBoolean(this.with_time)) {
          return ''
        }

        if (this.sameMonth()) {
          // edge case: prevent hyphen between date and year
          return this.end.add(this.difference, 'hours').format(`${this.time}`)
        }

        return this.end.add(this.difference, 'hours').format(`${this.time}`)
      },

      // Internal: Extracts the month from start time to add dividing line to display.
      dateHtml() {
        if (this.sameDate() && toBoolean(this.compact)) {
          const day = toBoolean(this.with_day) ? 'ddd, ' : ''

          return this.start.format(day + this.month + ' D' + this.year)
        }

        if (this.end) {
          if (this.sameDate()) {
            return this.start.format(`${this.month} D` + this.year)
          }
          if (this.sameMonth()) {
            return this.start.format(`${this.month} D`) + '-' + this.end.format('D, YYYY')
          }
          if (this.sameYear()) {
            return this.start.format(`${this.month} D`) + ' - ' + this.end.format(`${this.month} D, YYYY`)
          }

          return this.start.format(`${this.month} D, YYYY`) + ' - ' + this.end.format(`${this.month} D, YYYY`)
        }

        return this.start.format(`${this.month} D ${this.year}`)
      },

      // Public: The timezone of the user
      timeZone() {
        if (!toBoolean(this.with_time)) {
          return ''
        }

        if (this.timezone) {
          return this.timezone
        }

        const timeZoneOffset = new Date().getTimezoneOffset()

        return moment.tz.zone(moment.tz.guess()).abbr(timeZoneOffset)
      }
    },

    methods: {
      // Public: Compares start and end dates.
      //
      // Returns a Boolean.
      sameDate() {
        return new Date(this.start).getDate() == new Date(this.end).getDate() && this.sameMonth()
      },
      // Public: Compares start and end months.
      //
      // Returns a Boolean.
      sameMonth() {
        return new Date(this.start).getMonth() == new Date(this.end).getMonth()
      },

      // Public: Compares start and end years.
      //
      // Returns a Boolean.
      sameYear() {
        return new Date(this.start).getFullYear() == new Date(this.end).getFullYear()
      },

      // Public: Compares start and end hours of day.
      //
      // Returns a Boolean.
      sameHours() {
        return new Date(this.start).getHours() == new Date(this.end).getHours()
      },

      // Public: Compares start and end minutes of day.
      //
      // Returns a Boolean.
      sameMinutes() {
        return new Date(this.start).getMinutes() == new Date(this.end).getMinutes()
      },

      // Public: Compares time listed in the event AR with the user's local time.
      //
      // Returns an Integer.
      timeDifference(eventTime, browserTime) {
        var btime = - (browserTime / 60)
        return eventTime - btime
      }
    }
  }
</script>
