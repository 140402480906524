<!--
Public: Simplify the bootstrap glyphicon/fontawesome

Examples:
  <icon name="auto"></icon>
  <icon name="up-arrow"></icon>
  <icon name="lock"></icon>
-->

<template>
  <span v-if        = "isGlyphicon"
        :class      = "iconClass"
        aria-hidden = "true"
        @click      = "onClick">
  </span>
  <i v-else
     :class      = "iconClass"
     aria-hidden = "true"
     @click      = "onClick">
  </i>
</template>

<script>
  import {mapState} from 'vuex'
  import concat     from 'lodash/concat'
  import {present}  from '../lib/utils.js'

  export default {
    props: {
      // Extra CSS class(es) to add to the element (default: '').
      cssClass:  { default: '' },
      // Whether or not the icon should be invisible while still keeping a space
      // for the icon in the display (default: false).
      invisible: { default: false },
      // Name of the glyphicon (default: '').
      name:      { default: '' },
      // Fontawesome sub-type (regular, solid, light, brands, null)
      sub:       { default: '' },
      // Type is calculated based on whether or not this is an admin page. It can
      // however be overriden with this property.
      type:      { default: null }
    },

    computed: {
      ...mapState(['is_admin']),

      // Internal: Fontawesome sub type
      faSub() {
        return {
          '': '       fa',
          'regular': 'far',
          'solid': '  fas',
          'brands': ' fab',
          'light': '  fal'
        }[this.sub];
      },

      // Internal: Class for the icon.
      iconClass() {
        const classes = [this.invisible ? 'invisible' : '', this.cssClass]

        return concat('icon', classes, (this.isGlyphicon ? 'glyphicon glyphicon-' : `${this.faSub} fa-`) + this.name)
      },

      // Internal: Returns true if the type is fontawesome
      isGlyphicon() {
        return this.computedType == 'glyphicon'
      },

      // Internal: Return the type of icon to draw.
      computedType() {
        if (present(this.type)) return this.type

        return this.is_admin ? 'glyphicon' : 'fontawesome'
      }
    },

    methods: {
      onClick() {
        this.$emit('click')
      }
    }
  }
</script>
