// Public: Association model.
//
// code      - association code (eg. change-management)
// name      - association name (eg. kcs.change-management)
// title     - display title    (eg. Change Management Discussions)
//
// Returns a new Association object.

import has                  from 'lodash/has'
import merge                from 'lodash/merge'
import {compose}            from '../lib/functional.js'
import {capitalizeAllWords,
        convertStopWords}   from '../lib/string.js'

let associationModel = function(options) {
  let association = {
    code:  '',
    name:  '',
    title: ''
  }

  let shortNames = {
    'Organizational Effectiveness'   : 'Org Effectiveness',
    'Strategy Execution & Alignment' : 'Strategy Execution'
  }

  // Calculate the name/code/title based on the incoming options hash
  if (has(options, 'code')) {
    association.name  = 'kcs.' + options.code
    association.title = compose(capitalizeAllWords, convertStopWords)(options.code)
  }
  else if (has(options, 'name')) {
    association.code  = options.name.replace(/^[^\.]+\./, '')
    association.title = compose(capitalizeAllWords, convertStopWords)(association.code)
  }

  let model  = merge({}, association, options)
  model.name = options.shortName

  return model
}

export default associationModel
