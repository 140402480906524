<!--
How tabbed content with separate panels visible when their corresponding
tab is selected.

Attributes:
tabs     - Comma delimited list of tab names. Spaces are allowed
selected - Open this tab as the default tab.

Example:
<material-tabs tabs="Tab 1, Tab 2">
  <template v-slot:tab_1>
    Tab 1 content goes here
  </template>

  <template v-slot:tab_2>
    Tab 2 content goes here
  </template>
</material-tabs>

Notes: The template name is the downcased tab name used in the
tabs attribute whith spaced replaced with underscores.
-->
<template>
  <div :class="cssClass">
    <!-- Tab strip -->
    <div class="bold flex justify-between mb-1em mt-1em">
      <div v-for  = "tab in internalTabs"
           :class = "`b--material-blue w-45p text-center pointer ${selectedClass(tab)}`"
           @click = "onClick(tab)"
      >
        {{tab.name}}
      </div>
    </div>

    <!-- Content panes -->
    <div
      v-for  = "tab in internalTabs"
      :class = "paneClass(tab)"
    >
      <slot :ref="tab.code" :name="tab.code"></slot>
    </div>
  </div>
</template>

<script>
  import {present} from '../lib/utils.js'

  export default {
    props: {
      // Apply these classes to the component.
      cssClass: { default: null },
      // Name of tab that will be selected by default.
      selected: { default: null },
      // Comma delimited list of tab names.
      tabs:     { default: null }
    },

    data() {
      return {
        // Currently selected tab object.
        internalSelected: null
      }
    },

    computed: {
      // Internal: Array of tab codes.
      codes() {
        return this.internalTabs.map(tab => tab.code)
      },

      // Internal: List of tab objects.
      //
      // Example:
      // [{name: 'Tab 1', code: 'tab 1'}, {name: 'Tab 2', code: 'tab 2'}]
      internalTabs() {
        return this.tabs
                   .split(/\s*,\s*/)
                   .map((tab) => {
                     return { code: tab.toLowerCase()
                                       .replace(/\s/, '_'),
                              name: tab }
                   })
      },

      // Internal: Determine which tab to initially select.
      initialSelection() {
        let code = this.selected?.toLowerCase() || this.codes[0]

        return this.internalTabs.find((tab) => tab.code == code)
      }
    },

    methods: {
      // Internal: Classes to control the visiblity of the content panel.
      paneClass(tab) {
        return tab == this.internalSelected ? 'display-block' : 'display-none'
      },

      // Internal: Tab click event handler
      onClick(tab) {
        this.internalSelected = tab
      },

      // Internal: Classes to control the visual selection of a tab.
      selectedClass(tab) {
        return tab == this.internalSelected ? 'bb-2' : 'bb-0'
      }
    },

    mounted() {
      this.internalSelected = this.initialSelection
    }
  }
</script>
