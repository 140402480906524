<!--
Public: Component to display a dropdown list of downloadable attachments

Computed properties
  visible - whether or not the dropdown items are visible

Methods
  downloadItems - lists the download items to be populated into the dropdown
-->

<template>
  <div class="dropdown position-relative mt-1em">
    <div @click="menuOpen = !menuOpen" class="pointer attachments-dropdown-button">
      Download Now
      <!-- placeholder for spacing -->
      <div class="w-1em"></div>
      <icon :name="iconName" />
    </div>
    <div v-show="menuOpen"
      class="
        dropdown-content
        background-color-marketing-blue color-white
        bold width-min-content position-absolute z-index-1">
      <a v-for="asset in downloadAssets"
        :key    = "asset.id"
        :title  = "asset.attachment_file_name"
        :href   = "downloadUrl(asset)"
        target  = "_blank"
        class   = "btn background-color-marketing-blue color-white bold z-index-1">
        {{ asset.attachment_file_name }}
      </a>
    </div>
  </div>
</template>

<script>
  import {get} from '../lib/async.js'
  import Icon from './icon.vue'

  export default {
    components: {
      Icon
    },

    props: {
      postId: { default: null }
    },

    data() {
      return {
        // Public: True if the menu is open and false otherwise (default: false)
        menuOpen: false,
        // Public: List of downloadable assets used to populate the menu
        assets: []
      };
    },

    computed: {
      // Public: The list of downloadable items associated with the post excluding .vtt files
      //
      // Returns the list of downloadable items
      downloadAssets() {
        return this.assets.filter( item => item.is_downloadable )
      },

      // Public: The name of the icon to be displayed whether the menu is open or closed
      //
      // Returns a string for the name property of the icon
      iconName() {
          return this.menuOpen ? 'arrow-up' : 'arrow-down'
      }
    },

    methods: {
      // Public: Get the URL of a downloadable item
      //
      // asset - Get the URL for this downloadable item
      //
      // Returns a string
      downloadUrl(asset) {
        return `/file/${asset.id}/download`
      },
    },

    mounted() {
      get(`/api/posts/assets/${this.postId}`).then((results) => {
        this.assets = results
      })
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/includes/_colors.scss";

  .attachments-dropdown-button {
    display: flex;
    align-items: center;
    width: fit-content;

    background-color: $marketing-blue;
    color: white;
    font-weight: bold;
    padding: 0.75em 4em;
  }
</style>
