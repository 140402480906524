import concat      from 'lodash/concat'
import curry       from 'lodash/curry'
import lodashMerge from 'lodash/merge'
import tap         from 'lodash/tap'
import every       from 'lodash/every'
import some        from 'lodash/some'
import flow        from 'lodash/flow'

// Public: Alias lodash every() method to all()
export const all = every
// Public: Alias lodash some() method to every()
export const any = some
// Public: Alias lodash flow() to compose()
export const compose = flow

// Public: Function that returns true if a > b and false otherwise.
//
// a - Any object that responds to >.
// b - Any object that responds to >.
//
// Returns true if a > b and false otherwise.
export const gt = curry(function(a, b) {
  return a > b
})

export function merge() {
  return lodashMerge.apply(null, concat({}, arguments))
}

// Public: Negate the result of a predicate function.
//
// predicate - Predicate to negate.
//
// Example
//   _.isEmpty([1, 2, 3]); // => false
//   i4cp.f.not(_.isEmpty)([1, 2, 3]); // => true
//
// Returns a new function that, when called, returns the negative of what the predicate would have returned.
export function not(predicate) {
  return function() {
    return !predicate.apply(this, arguments)
  }
}

// Public: Tab the given data to produce some sort of side effect.
//
// tapper - Function to call to produce a side effect using the given data.
// data   - Produce a side effect using this data.
//
// Returns data.
export const tapData = curry(function(tapper, data) {
  return tap(data, tapper)
})
