<!--
Component that works around Vue's limitation preventing scripts from
runninging within the Vue context.

Notes:
just replace the offending <script> component with <forgiving-script>. This
component takes the attributes, builds a new script component and hooks it
into the <head> element.

For scripts that include content rather than reference a source file, include
the v-cloak attribute to hide the script contents, otherwise the JS code will
temporarily be visible on the page until vue completes loading. Example:

<forgiving-script v-cloak>
  ... interesting Javascript ...
</forgiving-script>
-->

<template>
  <span></span>
</template>

<script>
  import each from 'lodash/each'

  export default {
    mounted: function() {
      let script = document.createElement('script')

      // Build a script element with all the attributes provided to the component.
      each(this.$attrs, function(value, attribute) {
        script.setAttribute(attribute, value)
      });

      // Copy the component's javascript to the new element.
      if (this.$slots.default) {
        script.innerHTML = this.$slots.default[0].text
      }

      document.head.appendChild(script)
    }
  }
</script>
