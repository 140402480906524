<!--
Saved post indicator shows a bookmark icon that is grey when the post is not saved and has a color
when the post is saved. Clicking the icon toggles the post's saved state.
-->
<template>
  <span :class="cssClass" :title="hintText">
    <a @click.stop.prevent="updateCollection" class="add-post pointer">
      <icon :css-class = "bookmarkedClass"
            :name      = "iconToDisplay"
      >
      </icon>
    </a>
  </span>
</template>

<script>
  import AjaxLoadingIcon      from './ajax_loading_icon.vue'
  import Icon                 from './icon.vue'
  import { post, destroy }    from '../lib/async.js'
  import { blank, toBoolean } from '../lib/utils.js'

  export default {
    components: {
      AjaxLoadingIcon,
      Icon
    },

    props: {
      // Public: CSS classes to apply to component.
      cssClass:     { default: '' },
      // Public: Whether the Post is already saved to the User's collection.
      inCollection: { default: false },
      // Public: ID of Post to add.
      postId:       { default: null },
      // Public: ID of current member.
      userId:       { default: null },
      // Determines which icon to display to add to saved posts.
      saveIcon:     { default: 'bookmark' },
      // Determines which icon to display to remove from saved posts.
      unsaveIcon:   { default: 'bookmark' }
    },

    data() {
      return {
        // Internal: Whether the Post is in the User's collection.
        internalInCollection:   toBoolean(this.inCollection)
      }
    },

    computed: {
      // Internal: Change icon color based on whether it is saved.
      bookmarkedClass() {
        if (this.saveIcon == this.unsaveIcon && !this.internalInCollection) {
          return 'bookmark-post color-gray'
        }
        else {
          return 'bookmark-post color-material-light-blue'
        }
      },

      // Internal: Change icon based on whether it is saved.
      iconToDisplay() {
        return this.internalInCollection ? this.unsaveIcon : this.saveIcon
      },

      // Internal: Hint text for what clicking on icon does.
      hintText() {
        return this.internalInCollection ? 'Unsave post' : 'Save post'
      }
    },

    methods: {
      // Internal: DELETE to endpoint that removes Post from User's collection.
      removePost() {
        destroy(`/api/posts/remove-post`, { user_id: this.userId, post_id: this.postId })
        this.internalInCollection = false
      },

      // Internal: POST to endpoint that adds Post to User's collection.
      savePost() {
        post('/api/posts/save-post', { user_id: this.userId, post_id: this.postId })
        this.internalInCollection = true
      },

      // Internal: If the Post is a part of User's collection, remove.
      //           If Post is not in User's collection, add.
      updateCollection() {
        if (blank(this.postId) || blank(this.userId)) return

        if (this.internalInCollection) {
          this.removePost()
        } else {
          this.savePost()
        }
      }
    }
  }
</script>

<style lang="scss">
</style>
