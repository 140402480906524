<template>
  <div class="carousel-slide-item" :key="item.id">
    <div class="image-container">
      <a :href="permalink(item)" :target="target(item)">
        <img :src="item.public_image_url" :alt="item.image_name"/>
      </a>
    </div>
    <div class="carousel-item-details font-1-point-2em">
      <h3>
        <post-lock-icon v-if="no_external_url" :permalink="item.permalink" >
        </post-lock-icon>
        <a :href="permalink(item)" class="subject" :target="target(item)">
          {{ item.subject }}
        </a>
      </h3>
      <div v-if   = "item.body"
           v-html = "item.body"
           class  = "inline-block">
      </div>
    </div>
  </div>
</template>

<script>
  import PostLockIcon from '../components/post_lock_icon.vue'
  import {get}        from '../lib/async.js'
  import {blank}      from '../lib/utils.js'

  export default {
    components: {
      PostLockIcon
    },
    
    props: {
      // Object represention the current item.
      item: { default: null }
    },

    methods: {
      // Internal: return the external url if there is one, otherwise the permalink.
      permalink(item) {
        return item.external_url || item.permalink
      },

      // Internal: if the item has an external url, target a new tab.
      target(item) {
        return (item.external_url.match('(www|staging)\.i4cp\.com|localhost|^\/'))
             ? ''
             : 'window'
      }
    },

    computed: {
      no_external_url() {
        return blank(this.item.external_url)
      }
    },
  }
</script>
