<!--
Show a text field. Include a label or counter if specified.

Data Properties
  dataValue - Value to display in the input field (default: '').

Properties
  cssClass     - Extra CSS class(es) to add to the text input (default: '').
  label        - Show a label above the field if this is given (default: null).
  labelColumns - The number of columns, from 1 to 11, that the label should use (default: DEFAULT_LABEL_COLUMNS).
  maxCount     - Maximum number of characters that can be displayed in the field (default: null).
  orientation  - Orientation of the label relative to the text input. Valid values are 'horizontal' and 'vertical', 'below' (default: 'vertical').
  placeholder  - Placeholder text for empty input field (default: '').
  subText      - Small gray text displayed below the field (default: null).
  type         - Type of the text input (default: 'text').
  value        - Value to display in the input field (default: '').

Computed Properties
  inputClass          - Get the class for the <input> element.
  inputContainerClass - Get the class for the container around the <input> element.
  inputOrientation    - Get the input orientation.
  isHorizontal        - Is the orientation horizontal?
  isVertical          - Is the orientation vertical?
  labelClass          - Get the class for the label.
  numLabelColumns     - Get the number of label columns.

Methods
  broadcastToTextCounter - Broadcast the length of the field to the text counter component.
-->

<template>
  <div v-if="isTop" class="input-text form-group">
    <slot name="label">
      <label v-if="label" :for="elementId" v-html="label"></label>
    </slot>

    <div class="display-flex justify-left">
      <input v-if         = "type == 'password'"
             type         = "password"
             ref          = "input"
             :class       = "inputClass"
             :id          = "elementId"
             :name        = "elementName"
             :placeholder = "placeholder"
             :required    = "required"
             v-model      = "dataValue"
             @keyup       = "broadcastToTextCounter"
             @blur        = "onBlur" />

      <input v-else-if    = "type == 'email'"
             type         = "email"
             ref          = "input"
             :class       = "inputClass"
             :id          = "elementId"
             :name        = "elementName"
             :placeholder = "placeholder"
             :required    = "required"
             v-model      = "dataValue"
             @keyup       = "broadcastToTextCounter"
             @blur        = "onBlur" />

      <input v-else-if    = "type == 'hidden'"
             type         = "hidden"
             ref          = "input"
             :id          = "elementId"
             :name        = "elementName"
             v-model      = "dataValue" />

      <input v-else
             type         = "text"
             ref          = "input"
             :class       = "inputClass"
             :id          = "elementId"
             :name        = "elementName"
             :placeholder = "placeholder"
             :required    = "required"
             v-model      = "dataValue"
             @keyup       = "broadcastToTextCounter"
             @blur        = "onBlur" />

      <div v-show="showLoadingIcon" class="w-20 mt-5 ml-negative-30">
        <ajax-loading-icon/>
      </div>
    </div>

    <text-counter v-if       = "maxCount"
                  :entity    = "entity"
                  :field     = "field"
                  :max-count = "maxCount">
    </text-counter>

    <slot name="subText">
      <small v-if  = "subText"
             class = "text-muted">
        {{subText}}
      </small>
    </slot>
  </div>
</template>

<script>
  import TextCounter        from './text_counter.vue'
  import AjaxLoadingIcon    from './ajax_loading_icon.vue'
  import ElementIdAndName   from '../mixins/element_id_and_name.js'
  import {blank, toBoolean} from '../lib/utils.js'

  const DEFAULT_LABEL_COLUMNS = 3
  const MAX_COLUMNS           = 12
  const MIN_PASSWORD_LENGTH   = 8

  export default {
    mixins: [
      ElementIdAndName
    ],

    components: {
      AjaxLoadingIcon,
      TextCounter
    },

    data: function() {
      return {
        // Public: Value to display in the input field (default: '').
        dataValue: '',
        // Public: True if the control is enabled and false otherwise (default: this.initialEnabled).
        enabled:   toBoolean(this.initialEnabled),
        // Public: Show a progress snipper.
        showLoadingIcon: false
      }
    },

    props: {
      // Extra CSS class(es) to add to the text input (default: '').
      cssClass:       { default: '' },
      // Give this input focus on load. Note - if more then one input-text sets this, all bets are off.
      focus:          { default: false },
      // Is the control enabled (default: true).
      initialEnabled: { default: true },
      // Show a label above the field if this is given (default: null).
      label:          { default: null },
      // The number of columns, from 1 to 11, that the label should use (default: 3).
      labelColumns:   { default: DEFAULT_LABEL_COLUMNS },
      // Maximum number of characters that can be displayed in the field (default: null).
      maxCount:       { default: null },
      // Orientation of the label relative to the text input. Valid values are 'horizontal' and 'vertical' (default: 'vertical').
      orientation:    { default: 'top' },
      // Placeholder text for empty input field (default: '').
      placeholder:    { default: ''   },
      // Mark input as required.
      required:       { default: false },
      // Small gray text displayed below the field (default: null).
      subText:        { default: null },
      // Type of the text input (default: 'text').
      type:           { default: 'text' },
      // Value to display in the input field (default: '').
      value:          { default: ''   }
    },

    computed: {
      // Public: Get the value of the control's disabled attributed.
      //
      // Returns a string if the control is disabled and null otherwise.
      disabledValue() {
        return this.enabled ? null : 'disabled'
      },

      // Public: Get the class for the <input> element.
      //
      // Returns a string.
      inputClass() {
        return 'form-control ' + this.cssClass
      },

      // Public: Get the class for the container around the <input> element.
      //
      // Returns a string.
      inputContainerClass() {
        return `col-sm-${MAX_COLUMNS - this.numLabelColumns}`
      },

      // Public: Get the input orientation.
      //
      // Returns a string.
      inputOrientation() {
        if(this.orientation.match(/horizontal|vertical|left|right|top|bottom/)) {
          return this.orientation
        }

        return 'vertical'
      },

      // Public: Is the orientation bottom?
      //
      // Returns true if the orientation is bottom and false otherwise.
      isBottom() {
        return this.orientation == 'bottom'
      },

      // Public: Is the orientation horizontal?
      //
      // Returns true if the orientation is horizontal and false otherwise.
      isHorizontal() {
        return this.isLeft || this.isRight
      },

      // Public: Is the orientation left?
      //
      // Returns true if the orientation is left and false otherwise.
      isLeft() {
        return this.inputOrientation == 'left'
      },

      // Public: Is the orientation right?
      //
      // Returns true if the orientation is right and false otherwise.
      isRight() {
        return this.inputOrientation == 'right'
      },

      // Public: Is the orientation top?
      //
      // Returns true if the orientation is top and false otherwise.
      isTop() {
        return this.inputOrientation == 'top'
      },

      //
      // Returns true if the orientation is vertical and false otherwise.
      isVertical() {
        return this.top || this.bottom
      },

      // Public: Get the class for the label.
      //
      // Returns a string.
      labelClass() {
        return `control-label col-sm-${this.numLabelColumns}`
      },

      // Public: Get the number of label columns.
      //
      // Returns an integer.
      numLabelColumns() {
        const columns = parseInt(this.labelColumns)

        if (columns < 1 || columns > MAX_COLUMNS - 1) {
          return DEFAULT_LABEL_COLUMNS
        }

        return columns
      }
    },

    methods: {
      // Public: Broadcast the length of the field to the text counter component.
      broadcastToTextCounter() {
        if (blank(this.dataValue)) {
          return
        }

        const payload = {
          source: this,
          for:    this.elementId,
          count:  this.dataValue.length
        }

        this.$root.$emit('text-counter:update', payload)
      },

      // Internal: bubble the input blur event to this component's consumer.
      onBlur(e) {
        this.$emit('blur', e)
      },

      // Internal: Externally set the input value.
      onSetValue({ value, force }) {
        if (blank(this.dataValue) || force) {
          this.dataValue = value
        }
      }
    },

    created() {
      this.dataValue = this.value
    },

    mounted() {
      this.broadcastToTextCounter()

      if (this.focus) {
        this.$nextTick(() => {
          this.$refs.input.focus()
        })
      }

      this.$root.$on(`input-text-${this.elementId.replace(/_/g, '-')}:set-value`, this.onSetValue)
      this.$root.$on(`input-text-${this.elementId.replace(/_/g, '-')}:show-loading-icon`, _ => {
        this.showLoadingIcon = true
      })
      this.$root.$on(`input-text-${this.elementId.replace(/_/g, '-')}:hide-loading-icon`, _ => {
        this.showLoadingIcon = false
      })
    }
  }
</script>
