<!--
Public: Renders an intentionally simple modal and provides a slot for components.

<modal ref='modal' modal-container-classes = "w-800">
  <div>
    <div>You can put in basic HTML</div>
    <input-entity-autocomplete></input-entity-autocomplete>
    <div>Or even another component</div>
  </div>
</modal>
-->
<template>
  <transition name="modal">
    <div :class       = "maskClasses"
         v-if         = "showModal"
         @keydown.esc = "showModal = false">
      <div ref="modalWrapper" :class = "wrapperClasses">
        <focus-trap v-model        = "showModal"
                    :initial-focus = "() => $el">
          <div :class="containerClasses"
               :id    = "modalContainerId"
               @click = "onPreventClick"
               tabindex="-1">
            <slot></slot>
          </div>
        </focus-trap>
      </div>
    </div>
  </transition>
</template>

<script>
  import { FocusTrap }  from 'focus-trap-vue'
  import { isMobile,
           toBoolean }  from '../lib/utils.js'

  export default {
    components: {
      FocusTrap
    },

    props: {
      // classes for the modal mask.
      modalMaskClasses:      { default: '' },
      // classes for the modal wrapper.
      modalWrapperClasses:   { default: '' },
      // classes for the modal container.
      modalContainerClasses: { default: '' },
      // id for the modal container.
      modalContainerId:      { default: '' },
      // Prevent click event from bubbling if true.
      preventClick:          { default: true }
    },

    data() {
      return {
        // Public: Whether to display the modal.
        showModal: false
      }
    },

    computed: {
      // Internal: Classes for outermost div.
      maskClasses() {
        const fixed = isMobile() ? '' : 'fixed'

        return `modal-mask ${fixed} ${this.modalMaskClasses}`
      },

      // Internal: Wrapper classes for modal.
      wrapperClasses() {
        return `modal-wrapper ${this.modalWrapperClasses}`
      },

      // Internal: Classes for innermost div. Applied to modal.
      containerClasses() {
        const center = isMobile() ? 'position-relative top-15p' : 'ie-vertical-center'

        return `modal-container ${this.modalContainerClasses} ${center}`
      },

      // Internal: Import function from utils to determin if mobile device.
      isMobile: isMobile
    },

    methods: {
      // Public: Hide the modal.
      hide() {
        this.showModal = false
      },
      // Public: Show the modal.
      show() {
        this.showModal = true
      },

      // Internal: Selectively prevent the default click event.
      onPreventClick(event) {
        if (toBoolean(this.preventClick)) {
          event.preventDefault()
        }
      }
    },

    mounted() {
      let _this = this
      window.addEventListener('click', (e) => {
        if (e.target == _this.$refs.modalWrapper){
          e.preventDefault() // Added to stop the anchor tag in ruby post grid from being clicked on modal close.
          this.showModal = false
        }
      })
    }
  }
</script>

<style scoped>
  .modal-mask {
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .modal-wrapper {
    z-index: 9997;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  .modal-container {
    z-index: 9999;
    overflow-y: auto;
    margin: 1% auto;
    margin-top: 3em;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
  }

  .modal-enter-active, .modal-leave-active {
    transition: opacity .5s;
  }

  .modal-enter, .modal-leave-to {
    opacity: 0;
  }

  .anayltics-popup-max-height {
    max-height: calc(93% - 70px);
  }

</style>
