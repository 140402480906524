<!--
Public: A close button for a modal dialog or other control.

Properties
  type - Type of component to close. Valid values are 'modal' and 'alert' (default: 'modal').

Computed Properties
  closeType - Calculated type of the component to close. This ensures either '', 'modal' or 'alert' is returned.

Examples

  <button-close type="modal"></button-close>
-->

<template>
  <button type="button" class="close" :data-dismiss="closeType">
    <span aria-hidden="true">&times;</span>
    <span class="sr-only"></span>
  </button>
</template>

<script>
 export default {
   props: {
     // Type of component to close. Valid values are '', 'modal' and 'alert' (default: 'modal').
     type: { default: '' }
   },

   computed: {
     // Public: Calculated type of the component to close. This ensures either '', 'modal' or 'alert' is returned.
     //
     // Returns a string if the type is 'alert' or 'modal' and null otherwise (which prevents the data-dismiss attribute from displaying).
     closeType() {
       switch(this.type) {
         case 'alert':
           return 'alert'
           break
         case 'modal':
           return 'modal'
           break
         default:
           return null
           break
       }
     }
   }
  }
</script>
